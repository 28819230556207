import styled, { css } from 'styled-components'
import { media } from '../../styled'

export default styled.figure`
  background-position: 50% 0%;
  background-size: cover;
  display: block;
  height: 100%;
  margin: 0;
  width: 100%;
  ${({ url, xs }: any) => xs || url
    ? css`background-image: url('${xs || url}');`
    : css`background-image: none;`
  }
  ${({ sm }: any) => sm
    ? media.breakpoint.up('sm', css`background-image: url('${sm}');`)
    : ''
  }
  ${({ md }: any) => md
    ? media.breakpoint.up('md', css`background-image: url('${md}');`)
    : ''
  }
  ${({ lg }: any) => lg
    ? media.breakpoint.up('lg', css`background-image: url('${lg}');`)
    : ''
  }
  ${({ xl }: any) => xl
    ? media.breakpoint.up('xl', css`background-image: url('${xl}');`)
    : ''
  }
` as any
