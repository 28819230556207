import { selectLink } from '@hurleymc/sdk/selectors'
import { PageBuilderSpotlight, BroughtToYouBy, SpotlightVolunteer } from '@hurleymc/sdk/typings'

import * as React from 'react'
import styled, { css } from 'styled-components'

import { BroughtToYouByCaption, ChevyLink } from '../common'
import { Col, Container, Figure, Row, Spacer, Typography } from '../../../../components'
import { media } from '../../../../styled'

const Component = styled.div`
  background-color: ${({ theme }: any) => theme.colors.primary};
  color: white;
  min-height: 30rem;
  position: relative;
`

const AlignRowRight = styled(Row)`
  justify-content: flex-end;
`

const BackgroundFigure = styled(Figure)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  ${media.breakpoint.up('lg', css`
    background-position: 25% 0%;
  `)}
`

interface SpotlightProps extends PageBuilderSpotlight {
  className?: string
  broughtToYouBy?: BroughtToYouBy[]
  components: {
    Link: (props: any) => JSX.Element
  }
  spotlight?: SpotlightVolunteer[]
}

export default (props: SpotlightProps) => {
  if (props === null || typeof props !== 'object') {
    return null
  }

  if (props.spotlight && props.spotlight.length > 0) {
    const Link = props.components.Link
    const linkProps = selectLink({
      linkText: props.spotlight[0].linkText,
      to: [{ uri: props.spotlight[0].uri }] as any
    })

    const spotlight = props.spotlight[0]
    const image = spotlight.previewImage && spotlight.previewImage.length > 0 ? spotlight.previewImage[0] : {}

    return (
      <Component>
        <BackgroundFigure {...Object.assign(image, { url: null, xs: null })} />
        <Container>
          <AlignRowRight>
            <Col lg={1 / 2} xl={7 / 12}>
              <Spacer margin={{ xs: '4rem .5rem 4rem', lg: `4rem ${1 / 7 * 100}% 4rem .5rem` }}>
                <Spacer margin='0 0 1.5rem'>
                  <Typography color='inherit' variant='heading-5'>{props.identifier}</Typography>
                  {
                    props.broughtToYouBy &&
                    props.broughtToYouBy.length > 0 &&
                    <BroughtToYouByCaption
                      color='inherit'
                      component={Link}
                      hover='light'
                      title={props.broughtToYouBy[0].title}
                      to={props.broughtToYouBy[0].to}
                    />
                  }
                </Spacer>
                {
                  spotlight &&
                  <Spacer>
                    <Spacer margin='0 0 1.5rem'>
                      <Typography color='inherit' variant='heading-2'>{spotlight.header}</Typography>
                    </Spacer>
                    <Spacer margin='0 0 1rem'>
                      {
                        spotlight.summary &&
                        spotlight.summary.content &&
                        <Typography
                          color='inherit'
                          component='div'
                          dangerouslySetInnerHTML={{ __html: spotlight.summary.content }}
                          variant='body'
                        />
                      }
                      </Spacer>
                    <ChevyLink color='inherit' component={Link} hover='light' {...linkProps}>
                      {spotlight.linkText}
                    </ChevyLink>
                  </Spacer>
                }
              </Spacer>
            </Col>
          </AlignRowRight>
        </Container>
      </Component>
    )
  }

  return null
}
