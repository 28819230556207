import React from "react";
import styled from "styled-components";
import { HurleyThemeProps } from "../../../styled";
import { Quicksand } from "../../Typography/TypographyFonts";

export interface CheckboxInputProps {
  label: string;
  name: string;
  register: any;
  required?: boolean;
  error?: string;
  help?: string;
}

const CheckboxComponent = styled.div`
  display: block;
  margin-bottom: 1rem;
  label {
    color: ${({ theme }: HurleyThemeProps) => theme.colors.named.doveGray};
    display: flex;
    font-size: 1rem;
    font-family: ${Quicksand.family};
    font-weight: ${Quicksand.weight.medium};
    align-items: center;
    justify-content: flex-start;
  }
`;

export const CheckboxInput = ({
  label,
  name,
  register,
  required,
  error,
  help,
}: CheckboxInputProps) => {
  return (
    <CheckboxComponent>
      <div className="control">
        <label className="checkbox">
          <input
            type="checkbox"
            name={name}
            ref={register}
            style={{ marginRight: "5px" }}
            required={required}
          />
          {label}
        </label>
      </div>
      {help && <div className="help">{help}</div>}
      {error && <div className="help is-danger">{error}</div>}
    </CheckboxComponent>
  );
};
