import React from "react";
import { Icon } from "../..";
import styled from "styled-components";
import { StyledTextInput } from "./TextInput";

const StyledEmailInput = styled(StyledTextInput)``;

export const EmailInput = ({
  label,
  name,
  placeholder,
  register,
  required,
  error,
}) => (
  <StyledEmailInput>
    <label>{label}</label>
    <div
      className={`control has-icons-left has-icons-right ${
        error ? "is-danger" : ""
      }`}
    >
      <span className="icon is-left">
        <Icon className="icon-primary" icon="email" />
      </span>
      <input
        type="email"
        className={`input`}
        name={name}
        placeholder={placeholder}
        ref={register({ required })}
      />
      {error && (
        <span className="icon is-right is-danger">
          <Icon className="icon-primary" icon="warning" />
        </span>
      )}
    </div>
    {error && <div className="help is-danger">{error}</div>}
  </StyledEmailInput>
);
