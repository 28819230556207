import { Faq, PageBuilderFaq } from '@hurleymc/sdk/typings'

import * as React from 'react'
import styled, { css } from 'styled-components'

import { Col, Collapse, Container, Row, Spacer, Typography } from '../../../../components'
import { media, HurleyThemeProps } from '../../../../styled'

const IdentifierBlock = styled.div`
  background-color: ${({ theme }: HurleyThemeProps) => theme.colors.primary};
  color: white;
  padding: 0.5rem;
  text-align: center;
`

const BoldType = styled(Typography)`
  font-weight: bold;
`

const FaqBlock = styled.div`
  border: 1px solid ${({ theme }: HurleyThemeProps) => theme.colors.named.silver};
  border-top: 0;

  .answer {
    border-top: 1px solid ${({ theme }: HurleyThemeProps) => theme.colors.named.silver};
    padding: 1rem;

    *:first-child {
      margin-top: 0;
    }

    *:last-child {
      margin-bottom: 0;
    }
  }
`

const Question = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 1rem;

  &:hover {
    cursor: pointer;
  }

  .material-icons {
    transform: rotateX(0deg);
    transform-style: preserve-3d;
    transition: transform 200ms ease-out;

    &.active {
      transform: rotateX(180deg);
      transform-style: preserve-3d;
      transition: transform 200ms ease-in;
    }
  }
`

const StyledSpacer = styled(Spacer)`
  &:last-of-type {
    margin-bottom: 6rem;
    ${media.breakpoint.up(
      'lg',
      css`
        margin-bottom: 8rem;
      `
    )}
  }
`

interface FaqProps extends PageBuilderFaq {
  faqs?: Faq[]
}

type FaqState = {
  collapses?: any[];
}

export default class extends React.Component<FaqProps, FaqState> {
  constructor (props) {
    super(props)

    this.toggleCollapse = this.toggleCollapse.bind(this)

    this.state = {
      collapses: []
    }
  }

  toggleCollapse (collapse) {
    this.setState(state => {
      const index = state.collapses.indexOf(collapse)
      if (index > -1) {
        return {
          ...state,
          collapses: [...state.collapses.filter(item => item !== collapse)]
        }
      } else {
        return {
          ...state,
          collapses: [...state.collapses, collapse]
        }
      }
    })
  }

  render () {
    return (
      <StyledSpacer margin={{ xs: '0rem .5rem', lg: '0rem .5rem' }}>
        <Container>
          <Row xsJustifyContentCenter={true}>
            <Col lg={5 / 6}>
              <IdentifierBlock>
                <Typography color='inherit' variant='button'>
                  {this.props.identifier}
                </Typography>
              </IdentifierBlock>
              {Array.isArray(this.props.faqs) &&
                this.props.faqs.length > 0 &&
                this.props.faqs.map(faq => {
                  const collapseActive = this.state.collapses.indexOf(faq.id) > -1
                  return (
                    <FaqBlock key={faq.id}>
                      <Question onClick={this.toggleCollapse.bind(null, faq.id)}>
                        <BoldType variant='body'>{faq.question}</BoldType>
                        <i className={`material-icons ${collapseActive ? 'active' : ''}`}>keyboard_arrow_down</i>
                      </Question>
                      <Collapse open={collapseActive}>
                        <Typography
                          className='answer'
                          component='div'
                          dangerouslySetInnerHTML={{ __html: faq.answer.content }}
                          variant='body'
                        />
                      </Collapse>
                    </FaqBlock>
                  )
                })}
            </Col>
          </Row>
        </Container>
      </StyledSpacer>
    )
  }
}
