const headerDefault = { active: false, navStack: [] }

const FLIP_NAV = 'header/FLIP_NAV'
const FLIP_NAVBAR = 'header/FLIP_NAVBAR'
const KILL_NAVBAR = 'header/KILL_NAVBAR'
const POP_NAV = 'header/POP_NAV'
const PUSH_NAV = 'header/PUSH_NAV'

const selectHeader = state => state.header

const headerActions = {
  flipNav: payload => ({ type: FLIP_NAV, payload }),
  flipNavbar: () => ({ type: FLIP_NAVBAR }),
  killNavbar: () => ({ type: KILL_NAVBAR }),
  popNav: () => ({ type: POP_NAV }),
  pushNav: payload => ({ type: PUSH_NAV, payload })
}

const headerReducer = (state = headerDefault, { type, payload }) => {
  switch (type) {
    case FLIP_NAV:
      if (state.navStack.length > 0 && state.navStack[0] === payload) {
        return ({ ...state, active: false, navStack: [] })
      } else {
        return ({ ...state, active: true, navStack: [payload] })
      }

    case FLIP_NAVBAR:
      return ({ ...state, active: !state.active, navStack: state.active ? state.navStack : [] })

    case KILL_NAVBAR:
      return ({ ...state, active: false, navStack: [] })

    case POP_NAV:
      return ({ ...state, navStack: state.navStack.slice(0, state.navStack.length - 1) })

    case PUSH_NAV:
      return ({ ...state, navStack: [...state.navStack, payload] })

    default:
      return state
  }
}

export {
  headerActions,
  headerReducer,
  selectHeader
}
