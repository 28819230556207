import { createStore as reduxCreateStore, combineReducers } from "redux"
// import { reducer as formReducer } from 'redux-form'

import { headerReducer } from "@hurleymc/components"

const reducer = combineReducers({
  // TODO: Add form reducer once we need it (if ever)
  // form: formReducer,
  header: headerReducer,
})

const createStore = () => reduxCreateStore(reducer)
export default createStore
