export const Caveat = {
  family: "'Caveat', cursive",
  weight: {
    regular: 400,
  },
};

export const OpenSans = {
  family: "'Open Sans', sans-serif",
  weight: {
    light: 300,
    regular: 400,
    semiBold: 600,
    bold: 700,
  },
};

export const Quicksand = {
  family: "'Quicksand', sans-serif",
  weight: {
    regular: 400,
    medium: 500,
    bold: 700,
  },
};
