import * as React from 'react'
import styled, { css } from 'styled-components'

const Picture: any = styled.picture`
  ${({ height }: any) => height ? css`height: ${height};` : ''};
  ${({ width }: any) => width ? css`width: ${width};` : ''};
  max-height: ${({ maxHeight }: any) => maxHeight || '100%'};
  max-width: 100%;
`

const Img: any = styled.img`
  ${({ height }: any) => height ? css`height: ${height};` : ''};
  ${({ width }: any) => width ? css`width: ${width};` : ''};
  max-height: ${({ maxHeight }: any) => maxHeight || '100%'};
  max-width: 100%;
`

export default ({
  alt,
  height = null,
  maxHeight = null,
  sources = [],
  src,
  width = null
}) => (
  <Picture height={height} maxHeight={maxHeight} width={width}>
    {sources && sources.length > 0 && sources.map((source, key) => (
      <source key={key} media={source.media} srcSet={source.srcSet} />
    ))}
    <Img alt={alt} height={height} maxHeight={maxHeight} src={src} width={width} />
  </Picture>
)
