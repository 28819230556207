import * as React from 'react'
import styled from 'styled-components'
import Icon from '../Icon'
import { HurleyThemeProps } from '../../styled'

const Component = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;

  .icon-default,
  .icon-primary {
    margin-right: 0.5rem;
  }

  .icon-default {
    color: ${({ theme }: HurleyThemeProps) => theme.colors.named.silver};
  }

  .icon-primary {
    color: ${({ theme }: HurleyThemeProps) => theme.colors.primary};
  }
`

const Checkbox = styled.input`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

export default ({ input, ...props }: any) => {
  return (
    <Component>
      {props.checked ? (
        <Icon className='icon-primary' icon='check_box' />
      ) : (
        <Icon className='icon-default' icon='check_box_outline_blank' />
      )}
      <Checkbox name={props.name} value={props.value} type='checkbox' {...input} {...props} />
    </Component>
  )
}
