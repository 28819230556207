import * as React from 'react'
import styled, { css } from 'styled-components'
import { media } from '../../styled'

const View = styled.div``

const AspectRatio = css`
  &::before {
    content: "";
    display: block;
    padding-top: ${
      ({ ratio }: { ratio: string }) => (
        parseInt(ratio.split(':')[1], 10) / parseInt(ratio.split(':')[0], 10) * 100
      )
    }%;
    width: 100%;
  }
`

const Aspect: any = styled.div`
  display: block;
  position: relative;

  ${({ xs }: any) => xs ? AspectRatio : ''}
  ${({ xs, sm }: any) => (!xs) && sm ? media.breakpoint.up('sm', AspectRatio) : ''}
  ${({ xs, sm, md }: any) => (!(xs && sm)) && md ? media.breakpoint.up('md', AspectRatio) : ''}
  ${({ xs, sm, md, lg }: any) => (!(xs && sm && md)) && lg ? media.breakpoint.up('lg', AspectRatio) : ''}
  ${({ xs, sm, md, lg, xl }: any) => (!(xs && sm && md && lg)) && xl ? media.breakpoint.up('xl', AspectRatio) : ''}
`

const Fill = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const Ratio: any = styled.div`
  ${({ xs }: any) => xs ? Fill : ''}
  ${({ xs, sm }: any) => (!xs) && sm ? media.breakpoint.up('sm', Fill) : ''}
  ${({ xs, sm, md }: any) => (!(xs && sm)) && md ? media.breakpoint.up('md', Fill) : ''}
  ${({ xs, sm, md, lg }: any) => (!(xs && sm && md)) && lg ? media.breakpoint.up('lg', Fill) : ''}
  ${({ xs, sm, md, lg, xl }: any) => (!(xs && sm && md && lg)) && xl ? media.breakpoint.up('xl', Fill) : ''}
`

type AspectRatio = {
  children?: any,
  className?: string,
  maxHeight?: string,
  ratio: string,
  xs?: boolean,
  sm?: boolean,
  md?: boolean,
  lg?: boolean,
  xl?: boolean
}

export default ({
  className,
  maxHeight = 'none',
  ratio,
  xs = true,
  sm = false,
  md = false,
  lg = false,
  xl = false,
  ...props
}: AspectRatio) => (
  <View className={className}>
    <Aspect
      ratio={ratio}
      style={{ maxHeight }}
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
    ><Ratio
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
      {...props}
    />
    </Aspect>
  </View>
)
