import { selectLink } from '@hurleymc/sdk/selectors'
import { Card, VolumeInterface } from '@hurleymc/sdk/typings'

import * as React from 'react'

import ChevyLink from './ChevyLink'
import { AspectRatio, Col, Figure, Row, Spacer, Typography } from '../../../../components'

interface CardProps extends Card {
  image: VolumeInterface[]
}

interface CardListProps {
  cards: CardProps[]
  components: { Link: (props: any) => JSX.Element },
  linksHidden?: boolean
}

export default (props: CardListProps) => {
  const allCardsHaveImages = props.cards.filter(card => (card.image && card.image.length > 0)).length === props.cards.length

  return (
    <Row xsJustifyContentCenter={true}>
      {
        props.cards && props.cards.map((card, index) => {
          const image = card.image && card.image.length > 0 ? card.image[0] : {}
          const linkProps = selectLink(card)

          return (
            <Col xs={1} md={1 / 3} key={card.id || index}>
              <Spacer margin='0 0 4rem'>
                {
                  allCardsHaveImages && image.url &&
                  <Spacer margin='0 0 1rem'>
                    <AspectRatio ratio='4:3'>
                      <Figure {...image} />
                    </AspectRatio>
                  </Spacer>
                }
                {
                  card.header &&
                  <Typography variant='heading-4' gutterBottom={true}>{card.header}</Typography>
                }
                {
                  card.summary && card.summary.content &&
                  <Typography
                    component='div'
                    dangerouslySetInnerHTML={{ __html: card.summary.content }}
                    paragraph={true}
                    style={{ color: '#666' }}
                  />
                }
                {
                  !props.linksHidden &&
                  <ChevyLink color='default' component={props.components.Link} {...linkProps}>{linkProps.title}</ChevyLink>
                }
              </Spacer>
            </Col>
          )
        })
      }
    </Row>
  )
}
