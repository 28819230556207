import React, { Component, MouseEvent } from 'react'
import styled from 'styled-components'
import { AccordionSection } from './AccordionSection'

export interface AccordionProps {
  children: any
  multiple?: boolean
}

const initialState = { openSections: {} }

type AccordionState = Readonly<typeof initialState>

const AccordionWrapper = styled.div``

export class Accordion extends Component<AccordionProps, {}> {
  state: AccordionState = initialState

  constructor (props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick (title: string) {
    const { openSections } = this.state
    const { multiple } = this.props
    if (multiple) {
      this.setState({
        openSections: {
          ...openSections,
          [title]: !openSections[title]
        }
      })
    } else {
      this.setState({
        openSections: {
          [title]: !openSections[title]
        }
      })
    }
  }

  render () {
    const { children } = this.props
    const { openSections } = this.state
    return (
      <AccordionWrapper>
        {children.map(child => (
          <AccordionSection
            title={child.props.title}
            isOpen={!!openSections[child.props.title]}
            onClick={this.handleClick}
          >
            {child.props.children}
          </AccordionSection>
        ))}
      </AccordionWrapper>
    )
  }
}
