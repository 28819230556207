import React, { useEffect } from "react";
import styled, { css } from "styled-components";

import { Typography } from "../../../../components";
import { media, HurleyThemeProps } from "../../../../styled";

import {
  body,
  caption,
  heading1,
  heading2,
  heading3,
  heading4,
  heading5,
  heading6,
  pullQuote
} from "../../../../components/Typography/TypographyPresets";

const RedactorContent = styled(Typography)`
  h1 {
    ${heading1}
  }

  h2 {
    ${heading2}
  }

  h3 {
    ${heading3}
  }

  h4 {
    ${heading4}
  }

  h5 {
    ${heading5}
  }

  h6 {
    ${heading6}
  }

  blockquote {
    ${pullQuote}

    ${media.breakpoint.up(
      "lg",
      css`
        margin-left: -5%;
        margin-right: 0;
      `
    )}
  }

  p {
    ${body}
  }

  figure {
    display: table;
    margin: 0 0 1rem 0;
    ${media.breakpoint.down(
      "md",
      css`
        width: 100%;
        img {
          width: 100%;
        }
      `
    )}
    img {
      display: block;

    }
    figcaption {
      ${caption}
      caption-side: bottom;
      display: table-caption;
    }
  }

  /* img + figcaption {
    ${caption}
  } */

  .figure-iframe {
    figure {
      display: block;
      .widget-iframe {
        overflow: hidden;
        padding-top: 56.25%;
        position: relative;
        iframe {
          border: 0;
          height: 100% !important;
          left: 0;
          position: absolute;
          top: 0;
          width: 100% !important;
        }
      }
      figcaption {
        display: block;
      }
    }
  }

  .widget-iframe {
    overflow: hidden;
    padding-top: 56.25%;
    position: relative;
    iframe {
      border: 0;
      height: 100% !important;
      left: 0;
      position: absolute;
      top: 0;
      width: 100% !important;
    }
  }

  .widget-caption {
    ${caption}
    margin-top: -1rem;
  }

  /* TODO: Update this to use theme styles */
  table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 1rem;
    border-radius: 0;
    thead,
    tbody,
    tfoot {
      border: 1px solid #f1f1f1;
      background-color: #fefefe;
    }
    thead,
    tfoot {
      tr {
        background: transparent;
      }
      td,
      th {
        padding: 0.5rem 0.625rem 0.625rem;
        font-weight: bold;
        text-align: left;
      }
    }
    thead {
      background: #f8f8f8;
      color: #0a0a0a;
    }
    tbody {
      tr {
        &:nth-child(even) {
          border-bottom: 0;
          background-color: #f1f1f1;
        }
      }
      td {
        padding: 0.5rem 0.625rem 0.625rem;
      }
    }
    tfoot {
      background: #f1f1f1;
      color: #0a0a0a;
      td {
        padding: 0.5rem 0.625rem 0.625rem;
        font-weight: bold;
        text-align: left;
      }
    }
  }
`;

const updateHtml = html => {
  const figurePattern = /(<figure(.|\s)+?<\/figure>)/g;
  const figureMatches = html.match(figurePattern);
  if (figureMatches && figureMatches.length) {
    let newHtml = html;
    figureMatches.forEach(figureMatch => {
      const iframePattern = /(<iframe.+?<\/iframe>)/g;
      const iframeMatches = figureMatch.match(iframePattern);
      if (iframeMatches && iframeMatches.length) {
        newHtml = newHtml.replace(
          figureMatch,
          `<div class="figure-iframe">${figureMatch}</div>`
        );
        iframeMatches.forEach(iframeMatch => {
          newHtml = newHtml.replace(
            iframeMatch,
            `<div class="widget-iframe">${iframeMatch}</div>`
          );
        });
      }
    });
    return newHtml;
  }
  return html;
};

export const Redactor = props => {
  // ! Ugly hack to handle responzive iframes (videos, etc...)
  if (props.dangerouslySetInnerHTML && props.dangerouslySetInnerHTML.__html) {
    let { __html } = props.dangerouslySetInnerHTML;
    if (!__html || typeof __html !== "string") {
      return <></>;
    }
    props.dangerouslySetInnerHTML.__html = updateHtml(__html);
  }
  return <RedactorContent {...props} />;
};

Redactor.defaultProps = {
  component: "div"
};
