import { selectLink } from '@hurleymc/sdk/selectors'
import { PageBuilderTestimonial, Testimonial } from '@hurleymc/sdk/typings'
import * as React from 'react'
import styled, { css } from 'styled-components'

import { ChevyLink } from '../common'
import { Col, Container, Figure, Row, Spacer, Typography } from '../../../../components'
import { media } from '../../../../styled'

interface TestimonialProps extends PageBuilderTestimonial {
  className?: string
  components: { Link: (props: any) => JSX.Element }
  testimonial?: Testimonial[]
}

const Component = styled.div`
  background-color: ${({ theme }: any) => theme.colors.primary};
  color: white;
  min-height: 30rem;
  position: relative;
`

const BackgroundFigure = styled(Figure)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  ${media.breakpoint.up('lg', css`
    background-position: 75% 0%;
  `)}
`

interface TestimonialProps extends PageBuilderTestimonial {
  className?: string
  testimonial?: Testimonial[]
  components: { Link: (props: any) => JSX.Element }
}

export default (props: TestimonialProps) => {
  if (props === null || typeof props !== 'object') {
    return null
  }

  if (props.testimonial && props.testimonial.length > 0) {
    const testimonial = props.testimonial[0]

    const image = testimonial.previewImage && testimonial.previewImage.length > 0 ? testimonial.previewImage[0] : {}

    const Link = props.components.Link
    const linkProps = selectLink({
      linkText: props.testimonial[0].linkText,
      to: [{ uri: props.testimonial[0].uri }] as any
    })

    return (
      <Component>
        <BackgroundFigure {...Object.assign(image, { url: null, xs: null })} />
        <Container>
          <Row>
            <Col lg={1 / 2} xl={7 / 12}>
              <Spacer margin={{ xs: '4rem .5rem 4rem', lg: `4rem .5rem 4rem ${1 / 7 * 100}%` }}>
                <Spacer margin='0 0 1.5rem'>
                  <Typography color='inherit' variant='heading-5'>{props.identifier}</Typography>
                </Spacer>
                {
                  testimonial &&
                  <div>
                    <Typography color='inherit' variant='heading-2' gutterBottom={true}>{testimonial.header}</Typography>
                    <Spacer margin='0 0 1rem'>
                    {
                      testimonial.quote &&
                      testimonial.quote.content &&
                      <Typography
                        component='div'
                        color='inherit'
                        dangerouslySetInnerHTML={{ __html: testimonial.quote.content }}
                        variant='quote'
                      />
                    }
                    </Spacer>
                    <ChevyLink color='inherit' component={Link} hover='light' {...linkProps}>
                      {testimonial.linkText}
                    </ChevyLink>
                  </div>
                }
              </Spacer>
            </Col>
          </Row>
        </Container>
      </Component>
    )
  }

  return null
}
