import {
  selectLink
} from '@hurleymc/sdk/selectors'

import {
  FormBlackbaud,
  FormSurveyGizmo,
  PageBuilderForm
} from '@hurleymc/sdk/typings'

import * as React from 'react'
import SurveyGizmo from './FormSurveyGizmo'
import Blackbaud from './FormBlackbaud'
import { Col, Container, Row, Spacer } from '../../../../components'

interface PageBuilderFormProps extends PageBuilderForm {
  form: { __typename: string }[] & FormSurveyGizmo[] & FormBlackbaud[]
}

export default (props: PageBuilderFormProps) => {
  if (props === null || typeof props !== 'object') {
    return null
  }

  if (props.form && props.form.length > 0 && props.form[0].__typename) {
    switch (props.form[0].__typename) {
      case 'FormBlackbaud':
        const blackbaud = props.form[0] as FormBlackbaud
        return (
          <Spacer margin='2rem .5rem'>
            <Container>
              <Row xsJustifyContentCenter={true}>
                <Col lg={5 / 6}>
                  <Blackbaud id='bbox-root' formId={blackbaud.formId} />
                </Col>
              </Row>
            </Container>
          </Spacer>
        )
      case 'FormSurveyGizmo':
        const surveyGizmo = props.form[0] as FormSurveyGizmo
        return (
          <Spacer margin='2rem .5rem'>
            <Container>
              <Row xsJustifyContentCenter={true}>
                <Col lg={5 / 6}>
                  <SurveyGizmo header={surveyGizmo.header} surveyId={surveyGizmo.surveyId} surveyName={surveyGizmo.surveyName} />
                </Col>
              </Row>
            </Container>
          </Spacer>
        )
      default:
        return null
    }
  }

  return null
}
