import { selectLink } from "@hurleymc/sdk/selectors";
import {
  EntryInterface,
  ExternalLink,
  QuickLink,
  VolumeInterface,
  StoryStatementColorEnum
} from "@hurleymc/sdk/typings";

import * as React from "react";
import styled, { css } from "styled-components";

import {
  AspectRatio,
  Col,
  Container,
  Icon,
  Figure,
  Row,
  Typography
} from "../../../../components";
import { media, HurleyThemeProps } from "../../../../styled";
import { readableColor } from "polished";

// https://stackoverflow.com/questions/21646738/convert-hex-to-rgba
function hexToRgbA(hex, opacity = 1) {
  let c;
  if (hex) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
      "," +
      opacity +
      ")"
    );
  }
  console.error("bad hex");
  return null;
}

const Component = styled.div`
  display: block;
  position: relative;
  margin-bottom: 4rem;
  width: 100%;

  .quick-link-icon {
    color: ${({ theme }: HurleyThemeProps) => theme.colors.default};
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  ${media.breakpoint.up(
    "lg",
    css`
      .center-block {
        align-items: center;
      }

      .bg-white {
        background-color: white;
        border: 1px solid
          ${({ theme }: HurleyThemeProps) => theme.colors.named.silver};
        min-height: 16rem;
      }
    `
  )}
`;

const FigureContainer = styled(AspectRatio)`
  ${media.breakpoint.up(
    "lg",
    css`
      margin-bottom: -8rem;
    `
  )}
`;

const FigureBlock = styled.div`
  position: relative;
  background: radial-gradient(
    ellipse at center,
    ${({ theme }: HurleyThemeProps) => hexToRgbA(theme.colors.primary, 0)} 0%,
    ${({ theme }: HurleyThemeProps) => hexToRgbA(theme.colors.primary, 0.4)}
      100%
  );
  margin-bottom: 2rem;

  ${media.breakpoint.up(
    "lg",
    css`
      background: none;
      height: 100%;
    `
  )}
`;

const FigureImage = styled(Figure)`
  display: none;
  ${media.breakpoint.up(
    "lg",
    css`
      display: block;
    `
  )}
`;

const StoryStatement = styled.div`
  align-items: flex-start;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 4rem 1rem;

  ${media.breakpoint.up(
    "lg",
    css`
      /* align-items: flex-end; */
      bottom: 0;
      margin-bottom: 8rem;
      padding-right: 2rem;
      position: absolute;
      right: 0;
      top: 0;
      width: 50%;
    `
  )}
`;

const VerticalAlignCenter = styled.div`
  .quick-link {
    margin-bottom: 2rem;
  }

  .quick-link:last-child {
    margin-bottom: 0;
  }

  ${media.breakpoint.up(
    "lg",
    css`
      align-items: center;
      display: flex;
      height: 100%;
      margin: 0;
      padding: 2rem;
      width: 100%;

      .quick-link {
        margin-bottom: 0;
      }
    `
  )}
`;

const Redactor = styled(Typography)`
  > *:last-child {
    margin-bottom: 0;
  }
`;

const selectQuickLink = (quickLink: QuickLink) => {
  if (quickLink && quickLink.to && quickLink.to.length > 0) {
    const to: EntryInterface | ExternalLink = quickLink.to[0];
    if ((to as ExternalLink).externalUrl) {
      return {
        href: (to as ExternalLink).externalUrl
      };
    }
    if ((to as EntryInterface).uri) {
      return {
        to: (to as EntryInterface).uri
      };
    }
  }
};

const LinkBox = styled.div`
  display: block;
  font-weight: 700;
  margin-top: 1rem;
  a {
    display: flex;
    text-transform: uppercase;
  }
`;

type StoryWithQuickLinks = {
  components: { Link: (props: any) => JSX.Element };
  image: VolumeInterface[];
  linkText: string;
  quickLinks: QuickLink[];
  storyStatement: string;
  storyStatementColor?: string;
  to: EntryInterface[] | ExternalLink[];
};

interface StoryStatementHeadingProps extends HurleyThemeProps {
  storyStatementColor: StoryStatementColorEnum;
}

const StoryStatementHeadingElement = ({
  className,
  variant,
  color,
  children
}) => {
  return (
    <Typography className={className} variant={variant} color={color}>
      {children}
    </Typography>
  );
};

const StoryStatementHeading = styled(StoryStatementHeadingElement)`
  color: ${({ theme }: HurleyThemeProps) =>
    readableColor(theme.colors.primary, theme.colors.dark, theme.colors.light)};
  ${media.breakpoint.up(
    "lg",
    css`
      color: ${({ theme, storyStatementColor }: StoryStatementHeadingProps) =>
        storyStatementColor === "light"
          ? theme.colors.light
          : theme.colors.dark};
    `
  )}
`;

export default (data: StoryWithQuickLinks) => {
  const { Link } = data.components;
  const linkProps = selectLink(data);

  return (
    <Component>
      {(data.storyStatement ||
        linkProps ||
        (data.image && data.image.length > 0)) && (
        <FigureContainer xs={false} lg={true} ratio="9:4">
          <FigureBlock>
            {data.image && data.image.length > 0 && (
              <FigureImage {...Object.assign(data.image[0], { url: null })} />
            )}
            {(data.storyStatement || linkProps) && (
              <StoryStatement>
                {data.storyStatement && (
                  <StoryStatementHeading
                    variant="heading-1"
                    color="inherit"
                    storyStatementColor={data.storyStatementColor}
                  >
                    {data.storyStatement}
                  </StoryStatementHeading>
                )}
                {linkProps && linkProps.title && (
                  <LinkBox>
                    <Link {...linkProps}>
                      {linkProps.title}
                      <i className="material-icons">chevron_right</i>
                    </Link>
                  </LinkBox>
                )}
              </StoryStatement>
            )}
          </FigureBlock>
        </FigureContainer>
      )}
      <Container>
        <Row xsJustifyContentCenter={true}>
          <Col className="bg-white" lg={5 / 6}>
            <VerticalAlignCenter>
              <Container>
                <Row>
                  {data.quickLinks &&
                    data.quickLinks.length > 0 &&
                    data.quickLinks
                      .filter(quickLink => {
                        const flag =
                          quickLink.header &&
                          quickLink.icon &&
                          quickLink.id &&
                          quickLink.summary &&
                          quickLink.summary.content;

                        if (!flag) {
                          console.error("QuickLink was rejected: ", quickLink);
                        }

                        return flag;
                      })
                      .map(quickLink => {
                        const quickLinkProps = selectQuickLink(quickLink);

                        return (
                          <Col
                            className="quick-link"
                            key={quickLink.id}
                            lg={1 / 3}
                          >
                            <Link {...quickLinkProps}>
                              <Icon
                                className="quick-link-icon"
                                icon={quickLink.icon}
                              />
                              <Typography
                                gutterBottom={true}
                                variant="heading-6"
                              >
                                {quickLink.header}
                              </Typography>
                              {quickLink.summary && quickLink.summary.content && (
                                <Redactor
                                  component="div"
                                  dangerouslySetInnerHTML={{
                                    __html: quickLink.summary.content
                                  }}
                                  variant="body"
                                />
                              )}
                            </Link>
                          </Col>
                        );
                      })}
                </Row>
              </Container>
            </VerticalAlignCenter>
          </Col>
        </Row>
      </Container>
    </Component>
  );
};
