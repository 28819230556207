import styled from 'styled-components'
import { HurleyThemeProps } from '../../styled'

export default styled.div`
  overflow-x: scroll;

  table {
    border-collapse: separate;
    border-spacing: 2px;
  }

  thead {
    background: #f8f8f8;
    color: #fff;

    tr {
      background-color: ${({ theme }: HurleyThemeProps) => theme.colors.primary};

      &:hover {
        background-color: ${({ theme }: HurleyThemeProps) => theme.colors.primary};
      }
    }

    th {
      padding: 0.5rem 0.625rem 0.625rem;
      font-weight: 700;
      text-align: left;
    }
  }

  tbody {
    tr {
      background-color: ${({ theme }: HurleyThemeProps) => theme.colors.named.white};

      &:hover {
        background-color: ${({ theme }: HurleyThemeProps) => theme.colors.named.silver};
      }
    }

    tr:nth-child(2n) {
      background-color: ${({ theme }: HurleyThemeProps) => theme.colors.named.mercury};

      &:hover {
        background-color: ${({ theme }: HurleyThemeProps) => theme.colors.named.silver};
      }
    }

    td {
      padding: 0.5rem 0.625rem 0.625rem;
    }
  }
`
