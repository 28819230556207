import * as React from 'react'
import styled from 'styled-components'
import { HurleyThemeProps } from '../../styled'

const TextArea = styled.textarea`
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid ${({ theme }: HurleyThemeProps) => theme.colors.named.silver};
  border-radius: 0;
  color: ${({ theme }: HurleyThemeProps) => theme.colors.named.doveGray};
  display: block;
  font-size: 0.8rem;
  font-weight: 300;
  line-height: 1.5;
  padding: 0.5rem;
  width: 100%;
  color: #222;

  &::placeholder {
    color: #666;
    opacity: 1;
  }

  &:disabled,
  &[readonly] {
    background-color: #eee;
    opacity: 1;
  }
`

export default ({ input = {}, meta, ...props }) => (
  <div>
    <TextArea {...input} {...props} />
    {meta.touched && meta.error && <div>{meta.error}</div>}
  </div>
)
