import React from 'react'
import styled from 'styled-components'
import { readableColor, lighten, darken } from 'polished'

import { PageBuilderHero as PageBuilderHeroProps } from '@hurleymc/sdk/typings'
import { Redactor } from '../common/Redactor'
import { Container, Row, Col } from '../../../../components'
import { selectFirst } from '@hurleymc/sdk/selectors'
import { HurleyThemeProps } from '../../../../styled'

interface HeroProps extends PageBuilderHeroProps {
  components: { Link: (props: any) => JSX.Element }
}

const Content = styled.div`
  z-index: 1;
`

const Heading = styled.div`
  font-size: 0.875rem;
  text-transform: uppercase;
`

const ContentBody = styled(Redactor)`
  margin-top: 1rem;
`

const BackgroundImage = styled.div`
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.35;
  width: 100%;
  height: 100%;
  position: absolute;
`

const Hero = styled.div`
  padding: 8rem 0;
  position: relative;
  text-align: center;

  &.dark {
    background-color: ${({ theme }: HurleyThemeProps) => theme.colors.named.black};
    color: ${({ theme }: HurleyThemeProps) => theme.colors.named.white};
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: ${({ theme }: HurleyThemeProps) => theme.colors.named.white};
    }
  }

  .cta-link {
    border: 1px solid transparent;
    border-radius: 0;
    display: inline-block;
    font-family: 'Open Sans', san-serif;
    font-size: 0.875rem;
    font-weight: 700;
    letter-spacing: 0.5px;
    line-height: 1.375;
    margin-top: 1rem;
    padding: 0.75rem 1rem;
    text-align: center;
    text-rendering: optimizeLegibility;
    text-transform: uppercase;
    transition: all 0.25s ease-in-out;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;

    /* Primary */
    background-color: ${({ theme }: HurleyThemeProps) => theme.colors.primary};
    color: ${({ theme }: HurleyThemeProps) => theme.colors.named.white};

    &:hover {
      background-color: ${({ theme }: HurleyThemeProps) => lighten(0.15, theme.colors.primary)};
    }
  }
`

export const PageBuilderHero = ({
  heading,
  contentBody,
  ctaText,
  ctaLink,
  ctaStyle,
  colorScheme,
  backgroundImage,
  components: { Link }
}: HeroProps) => {
  const bgImage = selectFirst(backgroundImage)

  return (
    <Hero className={colorScheme}>
      {bgImage && <BackgroundImage style={{ backgroundImage: `url(${bgImage.xl})` }} />}
      <Content>
        <Container>
          <Row xsJustifyContentCenter={true}>
            <Col lg={5 / 6}>
              {heading && <Heading>{heading}</Heading>}
              {contentBody && (
                <ContentBody
                  color='inherit'
                  component='div'
                  dangerouslySetInnerHTML={{ __html: contentBody.content }}
                />
              )}
              {ctaText && ctaLink && (
                <Link href={ctaLink} className={`cta-link ${ctaStyle}`}>
                  {ctaText}
                </Link>
              )}
            </Col>
          </Row>
        </Container>
      </Content>
    </Hero>
  )
}
