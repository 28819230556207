import {
  PageBuilderRelatedStories,
  SpotlightVolunteer,
  Testimonial
} from "@hurleymc/sdk/typings";

import * as React from "react";
import styled from "styled-components";

import {
  AspectRatio,
  Col,
  Container,
  Figure,
  Row,
  Spacer,
  Typography
} from "../../../../components";
import { HurleyThemeProps } from "../../../../styled";

const normalizeUri = str =>
  str
    .split("/")
    .filter($ => $)
    .join("/");

const IdentifierBlock = styled.div`
  margin-bottom: 1rem;
  text-align: center;
`;

const CardContainer = styled.div`
  height: 100%;
  padding-bottom: 2rem;
`;

const Card = styled.div`
  border: 1px solid
    ${({ theme }: HurleyThemeProps) => theme.colors.named.silver};
  height: 100%;

  a {
    display: block;
    z-index: 1;
  }
`;

const Bar = styled.div`
  height: 1px;
  background-color: ${({ theme }: HurleyThemeProps) =>
    theme.colors.named.silver};
  width: 100%;
`;

const TypeBox = styled.div`
  line-height: 0;
  margin-top: -1rem;
  position: relative;
  text-align: center;
  z-index: 1;
`;

const Box = styled(Typography)`
  background-color: ${({ theme }: HurleyThemeProps) => theme.colors.primary};
  color: white;
  line-height: 0;
  padding: 1rem 1rem;
`;

interface RelatedStoriesProps extends PageBuilderRelatedStories {
  components: { Link: (props: any) => JSX.Element };
  data: {
    entries?: [SpotlightVolunteer | Testimonial];
  };
  location?: {
    pathname?: string;
  };
}

export default (props: RelatedStoriesProps) => {
  if (
    props === null ||
    typeof props !== "object" ||
    !props.data ||
    !props.data.entries ||
    !props.data.entries.length
  ) {
    return null;
  }

  const Link = props.components.Link;

  let entries: any[] =
    props.data && Array.isArray(props.data.entries) ? props.data.entries : [];

  if (entries.length > 0) {
    entries = entries.filter(
      ({ uri }) =>
        props.location &&
        props.location.pathname &&
        normalizeUri(props.location.pathname) !== normalizeUri(uri)
    );
  }

  if (entries.length > 3) {
    entries = entries.slice(0, 3);
  }

  return (
    <Spacer margin={{ xs: "6rem .5rem", lg: "8rem .5rem" }}>
      <IdentifierBlock>
        <Typography variant="heading-6">{props.identifier}</Typography>
      </IdentifierBlock>
      <Container>
        <Row xsJustifyContentCenter={true}>
          {entries.map(entry => {
            switch ((entry as any).__typename) {
              case "SpotlightVolunteer":
                const hfImage: any =
                  Array.isArray((entry as SpotlightVolunteer).hfImage) &&
                  (entry as SpotlightVolunteer).hfImage.length > 0
                    ? (entry as SpotlightVolunteer).hfImage[0]
                    : null;

                return (
                  <Col md={1 / 2} lg={1 / 3} key={entry.id}>
                    <CardContainer>
                      <Card>
                        <Link to={entry.uri}>
                          <AspectRatio ratio="4:3">
                            <Figure {...hfImage} />
                          </AspectRatio>
                          <Bar />
                          {entry.section && entry.section.name && (
                            <TypeBox>
                              <Box variant="button">Story</Box>
                            </TypeBox>
                          )}
                          <Spacer padding="1rem">
                            <Typography variant="heading-4" gutterBottom={true}>
                              {entry.header}
                            </Typography>
                            <Typography variant="body">
                              {entry.excerpt}
                            </Typography>
                          </Spacer>
                        </Link>
                      </Card>
                    </CardContainer>
                  </Col>
                );

              case "Testimonial":
                const image =
                  Array.isArray((entry as Testimonial).image) &&
                  (entry as Testimonial).image.length > 0
                    ? (entry as Testimonial).image[0]
                    : null;

                return (
                  <Col md={1 / 2} lg={1 / 3} key={entry.id}>
                    <CardContainer>
                      <Card>
                        <Link to={entry.uri}>
                          <AspectRatio ratio="4:3">
                            <Figure {...image} />
                          </AspectRatio>
                          <Bar />
                          {entry.section && entry.section.name && (
                            <TypeBox>
                              <Box variant="button">Story</Box>
                            </TypeBox>
                          )}
                          <Spacer padding="1rem">
                            <Typography variant="heading-4" gutterBottom={true}>
                              {entry.header}
                            </Typography>
                            <Typography variant="body">
                              {entry.excerpt}
                            </Typography>
                          </Spacer>
                        </Link>
                      </Card>
                    </CardContainer>
                  </Col>
                );

              default:
                return null;
            }
          })}
        </Row>
      </Container>
    </Spacer>
  );
};
