import React, { useState } from "react";
import ReactYouTube from "react-youtube";
import styled from "styled-components";

import AspectRatio from "../AspectRatio/AspectRatio";

const YouTubeBox: any = styled(AspectRatio)`
  .youtube-container {
    height: 100%;
  }
  iframe {
    height: 100%;
    width: 100%;
  }
`;

export default class extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = { playing: false };

    this.onPlay = this.onPlay.bind(this);
    this.onPause = this.onPause.bind(this);
    this.onEnd = this.onEnd.bind(this);
    this.onError = this.onError.bind(this);
  }

  // onReady () {}
  onPlay() {
    this.setState({ playing: true });
  }

  onPause() {
    this.setState({ playing: false });
  }

  onEnd() {
    this.setState({ playing: false });
  }

  onError() {
    this.setState({ playing: false });
  }

  render() {
    return (
      <YouTubeBox ratio="16:9" active={this.state.playing}>
        <ReactYouTube
          containerClassName="youtube-container"
          videoId={this.props.videoId}
          opts={{ color: "white" }}
          onPlay={this.onPlay}
          onPause={this.onPause}
          onEnd={this.onEnd}
          onError={this.onError}
        />
      </YouTubeBox>
    );
  }
}
