import React from "react";
import styled, { css } from "styled-components";
import { paramCase } from "change-case";

import {
  Button,
  Container,
  Row,
  Col,
  Typography,
} from "../../../../components";
import { media, HurleyThemeProps } from "../../../../styled";
import { Redactor } from "../common/Redactor";

const Wrapper = styled.div`
  margin-bottom: 4rem;
  margin-top: 4rem;
`;

const CenteredText = styled.div`
  text-align: center;
`;

const StyledProductCard = styled.div`
  border: 1px solid
    ${({ theme }: HurleyThemeProps) => theme.colors.named.gainsboro};
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  padding: 1rem;
  text-align: center;
  .product-card--image {
  }
  .product-card--title {
    margin-bottom: 1rem;
  }
  .product-card--description {
    margin-bottom: 1rem;
  }
  .product-card--price {
    margin-bottom: 1rem;
    margin-top: auto;
  }
  .product-card--actions {
    text-transform: uppercase;
  }
`;

const ProductCardButton = ({ product, pageUrl }) => {
  const productImage = product.image[0].sm;
  const buttonProps = {};
  buttonProps["data-item-id"] = paramCase(`${product.id} ${product.title}`);
  buttonProps["data-item-name"] = product.title;
  buttonProps["data-item-price"] = product.price;
  buttonProps["data-item-image"] = productImage;
  buttonProps["data-item-description"] = product.description;
  buttonProps["data-item-shippable"] = !!product.shippable;
  buttonProps["data-item-url"] = pageUrl;
  if (product.metadata) {
    const md = product.metadata.reduce((acc, curr) => {
      acc[curr.metadataKey] = curr.metadataValue;
      return acc;
    }, {});
    buttonProps["data-item-metadata"] = JSON.stringify(md);
  }
  if (product.customAttributes && product.customAttributes.length > 0) {
    product.customAttributes.forEach((ca, idx) => {
      buttonProps[`data-item-custom${idx + 1}-name`] = ca.attributeName;
      if (ca.attributeType.toLowerCase() === "dropdown") {
        buttonProps[`data-item-custom${idx + 1}-options`] = ca.attributeOptions;
      } else if (ca.attributeType.toLowerCase() !== "textbox") {
        buttonProps[`data-item-custom${idx + 1}-type`] = ca.attributeType;
      }
      if (ca.attributeDefault) {
        buttonProps[`data-item-custom${idx + 1}-value`] = ca.attributeDefault;
      }
    });
  }
  if (product.productCategories) {
    buttonProps["data-item-categories"] = product.productCategories;
  }
  return (
    <Button className="snipcart-add-item" {...buttonProps}>
      Buy now
    </Button>
  );
};

const ProductRow = styled(Row)``;

const ProductCol = styled(Col)`
  margin-bottom: 2rem;
  ${media.breakpoint.up(
    "lg",
    css`
      margin-bottom: 1rem;
    `
  )}
`;

const ProductCard = ({ product, pageUrl }) => {
  const productImage = product.image[0].sm;
  return (
    <StyledProductCard>
      <div className="product-card--image">
        <img alt={product.title} title={product.title} src={productImage} />
      </div>
      <div className="product-card--title">
        <Typography component="h4" variant="heading-3">
          {product.title}
        </Typography>
      </div>
      <div className="product-card--description">
        <Redactor
          dangerouslySetInnerHTML={{ __html: product.productDescription }}
        />
      </div>
      <div className="product-card--price">
        <Typography variant="heading-4">${product.price.toFixed(2)}</Typography>
      </div>
      <div className="product-card--actions">
        <ProductCardButton pageUrl={pageUrl} product={product} />
      </div>
    </StyledProductCard>
  );
};

export const PageBuilderProductListing = (props) => {
  const { contentField, heading, pageUrl, products } = props;
  return (
    <Wrapper>
      <Container>
        {heading && (
          <Row xsJustifyContentCenter={true}>
            <Col lg={5 / 6}>
              <CenteredText>
                <Typography
                  color="inherit"
                  component="h2"
                  gutterBottom={true}
                  variant="heading-2"
                >
                  {heading}
                </Typography>
              </CenteredText>
            </Col>
          </Row>
        )}
        {contentField && (
          <Row xsJustifyContentCenter={true}>
            <Col>
              <CenteredText>
                <Redactor dangerouslySetInnerHTML={{ __html: contentField }} />
              </CenteredText>
            </Col>
          </Row>
        )}
        {products && products.length > 0 && (
          <ProductRow xsJustifyContentCenter={true}>
            {products.map((product, idx) => {
              return (
                <ProductCol xs={1} md={1 / 3} lg={1 / 4} key={`product-${idx}`}>
                  <ProductCard pageUrl={pageUrl} product={product} />
                </ProductCol>
              );
            })}
          </ProductRow>
        )}
      </Container>
    </Wrapper>
  );
};
