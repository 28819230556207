import * as React from "react";
import styled from "styled-components";
import { lighten, readableColor } from "polished";

import Typography from "../Typography";
import { HurleyThemeProps } from "../../styled";

const Button = styled.button`
  -webkit-appearance: button;
  border: 1px solid transparent;
  border-radius: 0;
  display: inline-block;
  font-size: 0.8rem;
  line-height: 1.5;
  padding: 0.75em 1.5em;
  text-align: center;
  text-transform: inherit;
  transition: all 0.25s ease-in-out;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }

  /* Primary */
  background-color: ${({ theme }: HurleyThemeProps) => theme.colors.primary};
  color: ${({ theme }: HurleyThemeProps) =>
    readableColor(
      theme.colors.primary,
      theme.colors.named.white,
      theme.colors.named.black
    )};

  &:hover {
    background-color: ${({ theme }: HurleyThemeProps) =>
      lighten(0.15, theme.colors.primary)};
    color: ${({ theme }: HurleyThemeProps) =>
      readableColor(
        lighten(0.5, theme.colors.primary),
        theme.colors.named.white,
        theme.colors.named.black
      )};
  }

  &.disabled,
  &[disabled] {
    cursor: not-allowed;
    background-color: ${({ theme }: HurleyThemeProps) =>
      lighten(0.15, theme.colors.primary)};
    color: ${({ theme }: HurleyThemeProps) =>
      readableColor(
        lighten(0.5, theme.colors.primary),
        theme.colors.named.white,
        theme.colors.named.black
      )};
  }
`;

export default (props) => (
  <Typography variant={"button"} component={Button} {...props} />
);
