import { Typography } from '../../../../components'

import * as React from 'react'
import styled, { css } from 'styled-components'

const ChevyLink = styled(Typography)`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  ${({ align = 'left' }: any) => {
    switch (align) {
      case 'center': return css`justify-content: center;`
      case 'left': return css`justify-content: flex-start;`
      case 'right': return css`justify-content: flex-end;`
      default: return align
    }
  }}
`

export default ({
  align = 'left',
  children,
  color = null,
  component: Link,
  hover = null,
  ...props
}) => {
  if (children) {
    return (
      <ChevyLink align={align} color={color} component={Link} hover={hover} variant='button' {...props}>
        <span>{children}</span>
        <i className='material-icons'>chevron_right</i>
      </ChevyLink>
    )
  } else {
    return null
  }
}
