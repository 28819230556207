import {
  BroughtToYouBy,
  EntryInterface,
  ExternalLink,
  ImagesVolume,
  PageBuilderCalloutCardWithImage
} from '@hurleymc/sdk/typings'

import * as React from 'react'
import styled, { css } from 'styled-components'

import {
  Col,
  Container,
  Figure,
  Row
} from '../../../../components'
import { media } from '../../../../styled'

import ContentCard from './ContentCard'

const Component = styled(Container)`
  background-color: #222;
  color: #fff;
`

const Column = styled(Col)`padding: 0;`
const ColumnHiddenMobile = styled(Column)`
  display: none;
  ${media.breakpoint.up('lg', css`display: inherit;`)}
`

interface Image extends ImagesVolume {
  xs?: string
  sm?: string
  md?: string
  lg?: string
  xl?: string
}
export interface CalloutCardWithImageProps extends PageBuilderCalloutCardWithImage {
  broughtToYouBy?: BroughtToYouBy[]
  components: { Link: (props: any) => JSX.Element }
}

export default (props: CalloutCardWithImageProps) => {
  if (props === null || typeof props !== 'object') {
    return null
  }

  const image = props.image && props.image.length > 0 ? (props.image[0] as Image) : {}
  const contentCardProps = {
    components: { Link: props.components.Link },
    identifier: props.identifier || '',
    broughtToYouBy: props.broughtToYouBy as BroughtToYouBy[],
    header: props.header || '',
    content: props.summary && props.summary.content ? props.summary.content : '',
    linkText: props.linkText,
    to: props.to as (EntryInterface[] | ExternalLink[])
  }

  const Ordered = props.imagePosition !== 'right'
    ? (
      <Component>
        <Row>
          <ColumnHiddenMobile xs={1} lg={1 / 2}><Figure {...image} /></ColumnHiddenMobile>
          <Column xs={1} lg={1 / 2}><ContentCard {...contentCardProps} /></Column>
        </Row>
      </Component>
    ) : (
      <Component>
        <Row>
          <Column xs={1} lg={1 / 2}><ContentCard {...contentCardProps} /></Column>
          <ColumnHiddenMobile xs={1} lg={1 / 2}><Figure {...image} /></ColumnHiddenMobile>
        </Row>
      </Component>
    )

  return Ordered
}
