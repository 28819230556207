import {
  selectLink
} from '@hurleymc/sdk/selectors'

import {
  PageBuilderFeaturedContent
} from '@hurleymc/sdk/typings'

import * as React from 'react'
import styled from 'styled-components'

import { ChevyLink } from '../common'
import {
  Col,
  Container,
  Picture,
  Row,
  Spacer,
  Typography
} from '../../../../components'
import { theme } from '../../../../styled'

const Component = styled.div``

const FlexBox = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  flex-wrap: nowrap;
`

interface FeaturedContentProps extends PageBuilderFeaturedContent {
  className?: string
  components: { Link: (props: any) => JSX.Element }
}

export default (props: FeaturedContentProps) => {
  if (props === null || typeof props !== 'object') {
    return null
  }

  const Link = props.components.Link
  const linkProps = selectLink(props)

  const graphic: any = props.graphic && props.graphic.length > 0 ? props.graphic[0] : null

  return (
    <Component>
      <Spacer margin='4rem .5rem'>
        <Container>
          <Row>
            <Col md={5 / 12}>
            {
              graphic &&
              <Spacer margin={{ xs: '0 0 4rem', md: '0' }}>
                <Picture
                  alt={graphic.title}
                  sources={[
                    // order matters here
                    { media: `(min-width: ${theme.breakpoints.xl}px)`, srcSet: graphic.xl },
                    { media: `(min-width: ${theme.breakpoints.lg}px)`, srcSet: graphic.lg },
                    { media: `(min-width: ${theme.breakpoints.md}px)`, srcSet: graphic.md },
                    { media: `(min-width: ${theme.breakpoints.sm}px)`, srcSet: graphic.sm }
                  ]}
                  src={graphic.xs || graphic.url}
                  width='100%'
                />
              </Spacer>
            }
          </Col>
          <Col md={7 / 12}>
            <FlexBox>
              <Typography variant='heading-6' gutterBottom={true}>{props.identifier}</Typography>
              <Typography variant='heading-2' gutterBottom={true}>{props.header}</Typography>
              {
                props.summary &&
                props.summary.content &&
                <Typography component='div' dangerouslySetInnerHTML={{ __html: props.summary.content }} variant='body' />
              }
              <ChevyLink color='default' component={Link} {...linkProps}>{linkProps.title}</ChevyLink>
            </FlexBox>
          </Col>
        </Row>
      </Container>
      </Spacer>
    </Component>
  )
}
