import {
  selectLink
} from '@hurleymc/sdk/selectors'

import {
  PageBuilderCode
} from '@hurleymc/sdk/typings'

import * as React from 'react'
import styled, { css } from 'styled-components'

import { Typography } from '../../../../components'

interface CodeProps extends PageBuilderCode {
  className?: string
}

export default (props: CodeProps) => {
  if (props === null || typeof props !== 'object') {
    return null
  }

  if (props.html && props.html.content) {
    return <Typography component='div' dangerouslySetInnerHTML={{ __html: props.html.content }} variant='body' />
  }

  return null
}
