import { selectLink } from '@hurleymc/sdk/selectors'
import { EntryInterface, ExternalLink } from '@hurleymc/sdk/typings'

import * as React from 'react'
import styled from 'styled-components'

import { Typography } from '../../../../components'

const Bold = styled(Typography)`
  font-weight: bold;
`

interface BroughtToYouByProps {
  color?: string | null
  component: (props: any) => JSX.Element
  hover?: string | null
  title: string
  to: EntryInterface[] | ExternalLink[]
}

export default (props: BroughtToYouByProps) => {
  const color = props.color || null
  const hover = props.hover || null

  const Link = props.component
  const linkProps = selectLink({ linkText: props.title, to: props.to })

  return (
    <Typography color={color} variant='caption'>
      <span>Brought to you by&nbsp;</span>
      {
        Object.keys(linkProps).length > 0
        ? <Bold color={color} component={Link} hover={hover} {...linkProps}>{props.title}</Bold>
        : <Bold color={color} component='span'>{props.title}</Bold>
      }
    </Typography>
  )
}
