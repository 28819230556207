import * as React from 'react'
import styled from 'styled-components'

import Checkbox from '../Checkbox'

const CheckboxLabel = styled.label`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: .5rem;
`

export default ({ input, options = [] }) => {
  const { name, onChange, onBlur, onFocus } = input
  const inputValue = input.value

  return (
    <div>
      {options.map(({ label, value }, index) => {
        const handleChange = (event) => {
          const arr = [...inputValue]
          if (event.target.checked) {
            arr.push(value)
          } else {
            arr.splice(arr.indexOf(value), 1)
          }
          onBlur(arr)
          return onChange(arr)
        }

        return (
          <CheckboxLabel key={`checkbox-${index}`}>
            <Checkbox
              type='checkbox'
              name={`${name}['${index}']`}
              value={value}
              checked={inputValue.includes(value)}
              onChange={handleChange}
              onFocus={onFocus}
            />
            <span>{label}</span>
          </CheckboxLabel>
        )
      })}
    </div>
  )
}
