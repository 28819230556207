import { css } from 'styled-components'
import { media } from '../../../styled'

export default css`
  .desktop-site-bar {
    display: none;
  }

  ${media.breakpoint.up('lg', css`
    .desktop-site-bar {
      background-color: #222;
      color: white;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
    }

    .desktop-site-tab  {
      background: transparent;
      padding: .625rem 1rem;

      &.active {
        background: white;
      }
    }

    .desktop-site-cta {
      display: block;
    }
  `)}
`
