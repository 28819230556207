import { PageBuilderContentBlock } from '@hurleymc/sdk/typings'
import * as React from 'react'

import { Col, Container, Row, Spacer } from '../../../../components'

import { Redactor } from '../common/Redactor'

interface ContentBlockProps extends PageBuilderContentBlock {
  className?: string
}

export default (props: ContentBlockProps) => {
  if (props === null || typeof props !== 'object') {
    return null
  }

  return (
    <Spacer margin='2rem .5rem'>
      <Container>
        <Row xsJustifyContentCenter={true}>
          <Col lg={5 / 6}>
            {props.html && <Redactor component='div' dangerouslySetInnerHTML={{ __html: props.html.content }} />}
          </Col>
        </Row>
      </Container>
    </Spacer>
  )
}
