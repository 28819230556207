import * as React from 'react'
import styled, { css } from 'styled-components'
import { media } from '../../styled'

const SpacerComponent: any = ({ component = 'div', ...props }) => (
  React.createElement(component, props)
)

const Spacer: any = styled(SpacerComponent)`
  display: ${(props: any) => props.display || 'block'};

  ${(props: any) => (typeof props.margin === 'string' || props.margin instanceof String) && css`
    margin: ${props.margin};
  `}

  ${(props: any) => props.margin && props.margin.xs && css`
    margin: ${props.margin.xs};
  `}

  ${(props: any) => props.margin && props.margin.sm && media.breakpoint.up('sm', css`
    margin: ${props.margin.sm};
  `)}

  ${(props: any) => props.margin && props.margin.md && media.breakpoint.up('md', css`
    margin: ${props.margin.md};
  `)}

  ${(props: any) => props.margin && props.margin.lg && media.breakpoint.up('lg', css`
    margin: ${props.margin.lg};
  `)}

  ${(props: any) => props.margin && props.margin.xl && media.breakpoint.up('xl', css`
    margin: ${props.margin.xl};
  `)}

  ${(props: any) => (typeof props.padding === 'string' || props.padding instanceof String) && css`
    padding: ${props.padding};
  `}

  ${(props: any) => props.padding && props.padding.xs && css`
    padding: ${props.padding.xs};
  `}

  ${(props: any) => props.padding && props.padding.sm && media.breakpoint.up('sm', css`
    padding: ${props.padding.sm};
  `)}

  ${(props: any) => props.padding && props.padding.md && media.breakpoint.up('md', css`
    padding: ${props.padding.md};
  `)}

  ${(props: any) => props.padding && props.padding.lg && media.breakpoint.up('lg', css`
    padding: ${props.padding.lg};
  `)}

  ${(props: any) => props.padding && props.padding.xl && media.breakpoint.up('xl', css`
    padding: ${props.padding.xl};
  `)}
`

export default Spacer
