import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import Gallery from 'react-photo-gallery'
import Carousel, { Modal, ModalGateway } from 'react-images'

import { PageBuilderImageGallery as PageBuilderImageGalleryProps, ImagesVolume } from '@hurleymc/sdk/typings'
import { Spacer, Container, Row, Col, Typography } from '../../../../components'

interface ImageGalleryProps extends PageBuilderImageGalleryProps {
  className?: string
}

const Heading = styled(Typography)``

const GalleryWrapper = styled.div`
  margin-left: -16px;
  margin-right: -16px;
`

export const PageBuilderImageGallery = ({ heading, images }: ImageGalleryProps) => {
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  const galleryImages = images.map((image: any) => {
    return {
      src: image.md,
      xl: image.xl,
      title: image.title,
      alt: image.title,
      width: 1,
      height: 1
    }
  })
  return (
    <Spacer margin='2rem .5rem'>
      <Container>
        <Row xsJustifyContentCenter={true}>
          <Col lg={5 / 6}>
            {heading && <Heading variant='heading-3'>{heading}</Heading>}
            {galleryImages.length > 0 && (
              <GalleryWrapper>
                <Gallery photos={galleryImages} margin={16} onClick={openLightbox} />
                <ModalGateway>
                  {viewerIsOpen ? (
                    <Modal onClose={closeLightbox}>
                      <Carousel
                        currentIndex={currentImage}
                        views={galleryImages.map(x => ({
                          ...x,
                          src: x.xl,
                          caption: x.title
                        }))}
                      />
                    </Modal>
                  ) : null}
                </ModalGateway>
              </GalleryWrapper>
            )}
          </Col>
        </Row>
      </Container>
    </Spacer>
  )
}
