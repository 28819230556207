import { PageBuilderTimeline, TimelineEvent } from '@hurleymc/sdk/typings'
import * as React from 'react'
import styled, { css } from 'styled-components'

import {
  Col,
  Container,
  Picture,
  Row,
  Spacer,
  Typography
} from '../../../../components'
import { media, theme } from '../../../../styled'

const AlignCenter = styled(Spacer)`
  text-align: center;
`

const Clearfix = styled.div`
  &::after {
    clear: both;
    content: "";
    display: block;
  }
`

const TimelineEventBlock = styled.div`
  display: block;
  float: left;
  width: 100%;

  .event-card {
    display: block;
    position: relative;
    width: 100%;

    ${media.breakpoint.up('lg', css`
      width: 50%;
    `)}

    img {
      display: block;
      margin-bottom: 1rem;
      max-width: 100%;
      width: 100%;
    }
  }

  .event-content {
    margin-bottom: 4rem;
    margin-top: -1rem;

    ${media.breakpoint.up('lg', css`
      margin-bottom: 0;
    `)}
  }

  .event-line-segment {
    border-top: 2px dashed ${({ theme }: any) => theme.colors.primary};
    bottom: 0;
    content: '';
    display: none;
    position: absolute;
    top: 0;
    width: 4rem;

    ${media.breakpoint.up('sm', css`
      display: block;
    `)}
  }

  .event-badge {
    background-color: ${({ theme }: any) => theme.colors.primary};
    border-radius: 50%;
    color: white;
    height: 2rem;
    line-height: 2rem;
    position: absolute;
    text-align: center;
    top: -1rem;
    width: 2rem;
  }

  &:nth-child(even) {
    .event-card {
      float: right;
      padding-left: 0;

      ${media.breakpoint.up('sm', css`
        padding-left: 5rem;
      `)}

      .event-line-segment {
        border-left: 2px dashed ${({ theme }: any) => theme.colors.primary};
        left: -1px;
      }

      .event-badge {
        right: 0;
      }
    }
  }

  &:nth-child(odd) {
    .event-card {
      float: right;
      padding-left: 0;
      padding-right: unset;

      ${media.breakpoint.up('sm', css`
        padding-left: 5rem;
      `)}

      ${media.breakpoint.up('lg', css`
        float: left;
        padding-left: unset;
        padding-right: 5rem;
      `)}
    }

    .event-line-segment {
        border-left: 2px dashed ${({ theme }: any) => theme.colors.primary};
        border-right: unset;
        left: -1px;
        right: unset;

      ${media.breakpoint.up('lg', css`
        border-left: unset;
        border-right: 2px dashed ${({ theme }: any) => theme.colors.primary};
        left: unset;
        right: -1px;
      `)}
    }

    .event-badge {
      left: unset;
      right: 0;

      ${media.breakpoint.up('lg', css`
        left: 0;
        right: unset;
      `)}
    }
  }

  &:last-child {
    .event-card {
      .event-line-segment {
        border-left: none;
        border-right: none;
      }
    }
  }
`

interface TimeLineProps extends PageBuilderTimeline {
  className?: string
  timeline?: TimelineEvent[]
}

export default (props: TimeLineProps) => {
  if (props === null || typeof props !== 'object') {
    return null
  }

  return (
    <Spacer margin={{ xs: '6rem .5rem', lg: '8rem .5rem' }}>
      <Container>
        <Row xsJustifyContentCenter={true}>
          <Col lg={5 / 6}>
            <AlignCenter margin='0 0 3rem'>
              <Typography variant='heading-5'>{props.identifier}</Typography>
            </AlignCenter>
            <Clearfix>
            {
              props.timeline && props.timeline.length > 0 &&
              props.timeline.map((data, index) => {
                const image: any = data.image && data.image.length > 0 ? data.image[0] : null
                return (
                  <TimelineEventBlock key={index}>
                    <div className='event-card'>
                      <div className='event-line-segment'>
                        <div className='event-badge'>{index + 1}</div>
                      </div>
                      <div className='event-content'>
                      {
                        image &&
                        <Picture
                          alt={image.title}
                          sources={[
                            // order matters here
                            { media: `(min-width: ${theme.breakpoints.xl}px)`, srcSet: image.xl },
                            { media: `(min-width: ${theme.breakpoints.lg}px)`, srcSet: image.lg },
                            { media: `(min-width: ${theme.breakpoints.md}px)`, srcSet: image.md },
                            { media: `(min-width: ${theme.breakpoints.sm}px)`, srcSet: image.sm }
                          ]}
                          src={image.xs || image.url}
                        />
                      }
                      {
                        data.header &&
                        <Typography variant='heading-3' paragraph={true}>{data.header}</Typography>
                      }
                      {
                        data.summary && data.summary.content &&
                        <Typography component='div' dangerouslySetInnerHTML={{ __html: data.summary.content }} variant='body' />
                      }
                      </div>
                    </div>
                  </TimelineEventBlock>
                )
              })
            }
            </Clearfix>
          </Col>
        </Row>
      </Container>
    </Spacer>
  )
}
