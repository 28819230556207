import React, { Component, MouseEvent } from 'react'
import styled from 'styled-components'

export interface AccordionSectionProps {
  children: any
  useIconClick?: boolean
  isOpen: boolean
  onClick: (title: string) => void
  title: string
}

const AccordionSectionWrapper = styled.div`
  i {
    transition: all 0.125s ease-in-out;
    transform: rotate(90deg);
  }
  &.opened {
    i {
      transform: rotate(-90deg);
    }
  }
`

const AccordionSectionHeader = styled.div`
  background: orange;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
`

const AccordionSectionChildren = styled.div`
  background: #e8e8e8;
  padding: 0.5rem 1rem;
`

export class AccordionSection extends Component<AccordionSectionProps, {}> {
  iconRef: React.RefObject<any>

  constructor (props) {
    super(props)

    this.iconRef = React.createRef()
  }

  render () {
    const { children, isOpen, onClick, title, useIconClick } = this.props
    const handleClick = (e: MouseEvent) => {
      if (useIconClick) {
        if (this.iconRef.current.contains(e.target)) {
          onClick(title)
        }
      } else {
        onClick(title)
      }
    }
    return (
      <AccordionSectionWrapper className={isOpen ? 'opened' : ''}>
        <AccordionSectionHeader onClick={handleClick}>
          {title}: {isOpen}{' '}
          <i className={`material-icons`} ref={this.iconRef}>
            chevron_right
          </i>
        </AccordionSectionHeader>
        {isOpen && <AccordionSectionChildren>{children}</AccordionSectionChildren>}
      </AccordionSectionWrapper>
    )
  }
}
