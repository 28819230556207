import * as React from 'react'
import styled from 'styled-components'

import Typography from '../Typography'
import { link } from 'fs'

const Card = styled.div`
  margin-bottom: 2rem;
`

type CardProps = {
  header?: string,
  image?: {
    alt: string,
    src: string
  },
  components?: {
    Link?: (props: any) => JSX.Element
  },
  summary?: string
}

export default ({ header, image, components, summary, ...props }: CardProps) => {
  const Link = components && components.Link ? components.Link : null

  return (
    <Card {...props}>
      {image && image.src && <img src={image.src} alt={image.alt} />}

      {header && <Typography variant='heading-4' gutterBottom={true}>{header}</Typography>}

      {summary && <Typography component='div' dangerouslySetInnerHTML={{ __html: summary }} />}

      <Link />
    </Card>
  )
}
