import * as React from "react";
import styled, { css } from "styled-components";

import {
  AspectRatio,
  Col,
  Container,
  Figure,
  Row,
  Spacer,
  Typography
} from "../../../../components";
import { media } from "../../../../styled";

const FigureBlock = styled(AspectRatio)`
  display: none;
  margin-bottom: 4rem;

  ${media.breakpoint.up(
    "lg",
    css`
      display: inherit;
    `
  )}
`;

export const BasicPage = ({ title = null, images = null }) => {
  return (
    <>
      {images && images.length > 0 && (
        <FigureBlock ratio="9:4">
          <Figure {...Object.assign(images[0], { url: null })} />
        </FigureBlock>
      )}
      {title && (
        <Spacer margin="2rem .5rem">
          <Container>
            <Row xsJustifyContentCenter={true}>
              <Col lg={5 / 6}>
                <Typography variant="heading-2">{title}</Typography>
              </Col>
            </Row>
          </Container>
        </Spacer>
      )}
    </>
  );
};
