import { css } from 'styled-components'
import { Caveat, OpenSans, Quicksand } from './TypographyFonts'
import { media, HurleyThemeProps } from '../../styled'

export const heading1 = css`
  color: ${({ theme }: HurleyThemeProps) => theme.colors.named.mineShaft};
  font-family: ${Caveat.family};
  font-size: 3rem;
  font-weight: ${Caveat.weight.regular};
  line-height: 1;

  ${media.breakpoint.up(
    'md',
    css`
      font-size: 3.5rem;
    `
  )}

  ${media.breakpoint.up(
    'lg',
    css`
      font-size: 4rem;
    `
  )}

  ${media.breakpoint.up(
    'xl',
    css`
      font-size: 5rem;
    `
  )}
`

export const heading2 = css`
  color: ${({ theme }: HurleyThemeProps) => theme.colors.named.mineShaft};
  font-family: ${Quicksand.family};
  font-size: 2.25rem;
  font-weight: ${Quicksand.weight.regular};
  line-height: 1.1667;
`

export const heading3 = css`
  color: ${({ theme }: HurleyThemeProps) => theme.colors.named.mineShaft};
  font-family: ${OpenSans.family};
  font-size: 1.625rem;
  font-weight: ${OpenSans.weight.regular};
  line-height: 1.4615;
`

export const heading4 = css`
  color: ${({ theme }: HurleyThemeProps) => theme.colors.named.mineShaft};
  font-family: ${Quicksand.family};
  font-size: 1.375rem;
  font-weight: ${Quicksand.weight.regular};
  line-height: 1.1818;
`

export const heading5 = css`
  color: ${({ theme }: HurleyThemeProps) => theme.colors.named.mineShaft};
  font-family: ${OpenSans.family};
  font-weight: ${OpenSans.weight.semiBold};
  line-height: 1.5;
  text-transform: uppercase;
`

export const heading6 = css`
  color: ${({ theme }: HurleyThemeProps) => theme.colors.named.mineShaft};
  font-family: ${OpenSans.family};
  font-size: 0.875rem;
  font-weight: ${OpenSans.weight.semiBold};
  line-height: 1.8571;
  text-transform: uppercase;
`

export const body = css`
  color: ${({ theme }: HurleyThemeProps) => theme.colors.named.doveGray};
  font-family: ${OpenSans.family};
  font-weight: ${OpenSans.weight.regular};
`

export const body2 = css`
  color: ${({ theme }: HurleyThemeProps) => theme.colors.named.doveGray};
  font-family: ${OpenSans.family};
  font-size: 1.375rem;
  line-height: 1.6364;
  font-weight: ${OpenSans.weight.regular};
`

export const button = css`
  color: ${({ theme }: HurleyThemeProps) => theme.colors.named.mineShaft};
  display: inline-block;
  font-family: ${OpenSans.family};
  font-size: 0.875rem;
  line-height: 1.375;
  font-weight: ${OpenSans.weight.bold};
  letter-spacing: 0.5px;
  text-transform: uppercase;
`

export const caption = css`
  background: ${({ theme }: HurleyThemeProps) => theme.colors.named.whiteSmoke};
  border: none;
  color: ${({ theme }: HurleyThemeProps) => theme.colors.named.mineShaft};
  font-size: 0.75rem;
  font-style: normal;
  padding: 1rem;

  &.strong,
  .strong {
    font-weight: 700;
  }
`

export const quote = css`
  color: ${({ theme }: HurleyThemeProps) => theme.colors.named.white};
  font-family: ${Caveat.family};
  font-size: 2.625em;
  font-weight: 400;
  line-height: 1.3333;
  text-decoration: none;
`

export const pullQuote = css`
  color: ${({ theme }: HurleyThemeProps) => theme.colors.primary};
  font-family: ${Quicksand.family};
  font-size: 1.875em;
  font-weight: 600;
  line-height: 1.6;
  margin-left: 0;
  text-decoration: none;
`
