import { css } from 'styled-components'
import { theme } from './theme'

class BreakpointFactory {
  private theme

  constructor ($theme = {}) {
    this.theme = { ...this.theme, ...$theme }
  }

  up (media, rules) {
    return css`
      @media (min-width: ${this.theme.breakpoints[media]}px) {
        ${rules}
      }
    `
  }

  // I have no clue if this works; will test later, if needed
  down (media, rules) {
    return css`
      @media (max-width: ${this.theme.breakpoints[media] - 0.02}px) {
        ${rules}
      }
    `
  }
}

export const media = {
  breakpoint: new BreakpointFactory(theme),
  print: rules => css`
    @media print {
      ${rules}
    }
  `
}
