import * as React from "react";
import styled from "styled-components";

const createScript = (ref) => {
  if (typeof document !== "undefined") {
    const script = document.createElement("script");

    script.setAttribute("type", "text/javascript");
    script.src = "https://bbox.blackbaudhosting.com/webforms/bbox-min.js";
    script.type = "text/javascript";
    ref.appendChild(script);
  }
};

type BlackbaudForm = {
  id: string;
  formId: string;
};

const FormWrapper = styled.div`
  legend,
  .BBFormContainer {
    color: #000 !important;
  }
`;

export default class extends React.Component<BlackbaudForm, {}> {
  private createdScript: boolean;
  private formRef: React.RefObject<HTMLDivElement>;

  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  componentDidMount() {
    if (!this.createdScript && this.formRef.current && this.props.formId) {
      if (typeof window !== "undefined") {
        const root = window as any;
        // Fix for Blackbaud function `showForm` to ensure that it doesn't add
        // duplicate scripts and links to the page that break the page
        if (root.bbox) {
          root.location.reload();
        }

        root.bboxInit = () => {
          root.bbox.showForm(this.props.formId);
        };
      }
      createScript(this.formRef.current);
      this.createdScript = true;
    }
  }

  render() {
    if (this.props.id && this.props.formId) {
      return <FormWrapper id={this.props.id} ref={this.formRef} />;
    } else {
      return null;
    }
  }
}
