import { ThemeProps } from "styled-components";

export interface Theme {
  breakpoints: {
    [key: string]: number;
  };
  colors: {
    default: string;
    dark: string;
    light: string;
    named: {
      alabaster: string;
      alabasterContrast: string;
      black: string;
      blackContrast: string;
      charcoal: string;
      charcoalContrast: string;
      curiousBlue: string;
      curiousBlueContrast: string;
      doveGray: string;
      doveGrayContrast: string;
      eucalyptus: string;
      eucalyptusContrast: string;
      gainsboro: string;
      gainsboroContrast: string;
      hurleyBlue: string;
      hurleyBlueContrast: string;
      hurleyFoundation: string;
      hurleyFoundationContrast: string;
      mercury: string;
      mercuryContrast: string;
      mineShaft: string;
      mineShaftContrast: string;
      mojo: string;
      mojoContrast: string;
      nobel: string;
      silver: string;
      silverContrast: string;
      whisper: string;
      white: string;
      whiteContrast: string;
      whiteSmoke: string;
    };
    primary: string;
    primaryContrast: string;
    serviceLine: {
      bariatrics: string;
      bariatricsContrast: string;
      bodyServices: string;
      bodyServicesContrast: string;
      cancerCare: string;
      cancerCareContrast: string;
      heartDisease: string;
      heartDiseaseContrast: string;
      hernias: string;
      herniasContrast: string;
      orthopedics: string;
      orthopedicsContrast: string;
      patientServices: string;
      patientServicesContrast: string;
      pediatricHealth: string;
      pediatricHealthContrast: string;
      seniorServices: string;
      seniorServicesContrast: string;
      sleepDiagnostics: string;
      sleepDiagnosticsContrast: string;
      strokeCare: string;
      strokeCareContrast: string;
      womensHealth: string;
      womensHealthContrast: string;
    };
  };
  components: {
    body: {
      background: string;
    };
    button: {
      background: string;
      color: string;
      hoverBackground: string;
      hoverColor: string;
    };
    html: {
      background: string;
    };
    link: {
      color: string;
      hoverColor: string;
    };
  };
  containerMaxWidths: {
    [key: string]: number;
  };
  spacing: {
    [key: string]: number;
  };
}

export interface HurleyThemeProps extends ThemeProps<Theme> {
  theme: Theme;
}

const themeColors = {
  named: {
    alabaster: "#fcfcfc",
    alabasterContrast: "#222",
    black: "#000",
    blackContrast: "#fff",
    charcoal: "#333",
    charcoalContrast: "#fff",
    curiousBlue: "#1a97ec",
    curiousBlueContrast: "#fff",
    doveGray: "#666",
    doveGrayContrast: "#fff",
    eucalyptus: "#38A169",
    eucalyptusContrast: "#fff",
    gainsboro: "#e0e0e0",
    gainsboroContrast: "#222",
    hurleyBlue: "#105a8f",
    hurleyBlueContrast: "#fff",
    hurleyFoundation: "#9564B8",
    hurleyFoundationContrast: "#fff",
    mercury: "#e8e8e8",
    mercuryContrast: "#222",
    mineShaft: "#222",
    mineShaftContrast: "#fff",
    mojo: "#c53e3e",
    mojoContrast: "#fff",
    nobel: "#999",
    silver: "#cbcbcb",
    silverContrast: "#333",
    whisper: "#eeeeee",
    white: "#fff",
    whiteContrast: "#222",
    whiteSmoke: "#f2f2f2",
  },
  serviceLine: {
    bariatrics: "#00A060",
    bariatricsContrast: "#fff",
    bodyServices: "#105A8F",
    bodyServicesContrast: "#fff",
    cancerCare: "#FF69B0",
    cancerCareContrast: "#fff",
    heartDisease: "#E52525",
    heartDiseaseContrast: "#fff",
    hernias: "#32BDA8",
    herniasContrast: "#fff",
    orthopedics: "#F48C78",
    orthopedicsContrast: "#fff",
    patientServices: "#1A97EC",
    patientServicesContrast: "#fff",
    pediatricHealth: "#FF8F1F",
    pediatricHealthContrast: "#fff",
    seniorServices: "#9E5183",
    seniorServicesContrast: "#fff",
    sleepDiagnostics: "#C68ED2",
    sleepDiagnosticsContrast: "#222",
    strokeCare: "#98D37E",
    strokeCareContrast: "#222",
    womensHealth: "#C21C6A",
    womensHealthContrast: "#fff",
  },
};

const colors = {
  ...themeColors,
  default: themeColors.named.curiousBlue,
  dark: themeColors.named.mineShaft,
  light: themeColors.named.mercury,
  primary: themeColors.named.hurleyBlue,
  primaryContrast: themeColors.named.white,
};

export const theme: Theme = {
  colors,
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
  // TODO: Perhaps base components on primary / default etc...
  components: {
    body: {
      background: themeColors.named.whisper,
    },
    button: {
      background: themeColors.named.curiousBlue,
      color: themeColors.named.curiousBlueContrast,
      hoverBackground: themeColors.named.hurleyBlue,
      hoverColor: themeColors.named.hurleyBlueContrast,
    },
    html: {
      background: themeColors.named.mineShaft,
    },
    link: {
      color: themeColors.named.curiousBlue,
      hoverColor: themeColors.named.hurleyBlue,
    },
  },
  containerMaxWidths: {
    xl: 1440,
  },
  spacing: {
    unit: 8,
  },
};
