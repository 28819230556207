import React from "react";
import styled from "styled-components";
import { StyledTextInput } from "./TextInput";
import { Icon } from "../..";

const StyledMoneyInput = styled(StyledTextInput)``;

export const MoneyInput = ({
  label,
  name,
  placeholder,
  register,
  required,
  error,
  onChange,
}) => (
  <StyledMoneyInput className="field">
    <label>{label}</label>
    <div
      className={`control has-icons-left has-icons-right ${
        error ? "is-danger" : ""
      }`}
    >
      <span className="icon is-left">$</span>
      <input
        type="number"
        className={`input`}
        name={name}
        placeholder={placeholder}
        ref={register({ required })}
        min={0}
        onChange={onChange}
      />
      {error && (
        <span className="icon is-right is-danger">
          <Icon className="icon-primary" icon="warning" />
        </span>
      )}
    </div>
    {error && <div className="help is-danger">{error}</div>}
  </StyledMoneyInput>
);
