import React from "react";
import styled, { css } from "styled-components";

import {
  Container,
  Col,
  Row,
  PaymentForm,
  PaymentFormProps,
  Typography,
} from "../../../../components";
import { Redactor } from "../common/Redactor";
import { media, HurleyThemeProps } from "../../../../styled";

const StyledRedactor = styled(Redactor)`
  margin-top: 1rem;
`;

const StyledPageBuilderPayment = styled(Container)`
  background: ${({ theme }: HurleyThemeProps) => theme.colors.named.white};
  padding: 2rem;
  /* ${media.breakpoint.up(
    "lg",
    css`
      padding: 2rem 0;
    `
  )} */
`;

const StyledRow = styled(Row)`
  margin-bottom: 2rem;
`

export const PageBuilderPayment = (props: PaymentFormProps) => {
  return (
    <StyledPageBuilderPayment>
      <StyledRow xsJustifyContentCenter={true}>
        <Col xs={1} lg={3 / 6}>
          {props.subheading && <Typography variant="heading-6">{props.subheading}</Typography>}
          <Typography variant="heading-2">{props.productName}</Typography>
          {props.content && (
            <StyledRedactor
              color="inherit"
              component={"div"}
              dangerouslySetInnerHTML={{ __html: props.content }}
            />
          )}
        </Col>
      </StyledRow>
      <Row xsJustifyContentCenter={true}>
        <Col xs={1} lg={3 / 6}>
          <PaymentForm {...props} />
        </Col>
      </Row>
    </StyledPageBuilderPayment>
  );
};
