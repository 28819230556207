import React from "react";
import styled from "styled-components";
import { StripeCardElementOptions } from "@stripe/stripe-js";
import { useTheme } from "styled-components";
import { HurleyThemeProps } from "../../../styled";
import { Quicksand } from "../../Typography/TypographyFonts";

import { StyledCardElement } from ".";
import { Theme } from "../../../styled";

export interface CardInputProps {
  error?: string;
  label?: string;
}

const createCardElementOptions = () => {
  const theme = useTheme() as Theme;
  const options: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: theme.colors.named.doveGray,
        color: theme.colors.named.doveGray,
        fontSize: "16px",
        lineHeight: "1.5",
        padding: 10,
        "::placeholder": {
          color: theme.colors.named.silver,
        },
        ":focus": {
          iconColor: theme.colors.named.doveGray,
        },
      },
      invalid: {
        iconColor: theme.colors.named.mojo,
        color: theme.colors.named.mojo,
      },
    },
  };
  return options;
};

const CardInputComponent = styled.div`
  display: block;
  margin-bottom: 1rem;
  label {
    color: ${({ theme }: HurleyThemeProps) => theme.colors.named.doveGray};
    display: block;
    font-size: 1rem;
    font-family: ${Quicksand.family};
    font-weight: ${Quicksand.weight.medium};
    margin-bottom: 0.25rem;
  }
  .help {
    display: block;
    font-size: 1rem;
    margin-top: 0.25rem;
  }
  .is-danger {
    color: ${({ theme }: HurleyThemeProps) => theme.colors.named.mojo};
  }
`;

export const CardInput = ({ error, label }: CardInputProps) => {
  return (
    <CardInputComponent>
      {label && <label>{label}</label>}
      <StyledCardElement options={createCardElementOptions()} />
      {error && <div className="help is-danger">{error}</div>}
    </CardInputComponent>
  );
};
