import { VideoYouTube } from "@hurleymc/sdk/typings";
import * as React from "react";

import { default as YouTube } from "./YouTube";

interface VideoType extends VideoYouTube {
  __typename: "VideoYouTube";
}

const makeVideo = (video: VideoType) => {
  switch (video.__typename) {
    case "VideoYouTube":
      return <YouTube videoId={video.videoId} />;
    default:
      return null;
  }
};

interface VideoProps {
  video: VideoYouTube[];
}

export default (props: VideoProps) => {
  if (
    props === null ||
    typeof props !== "object" ||
    !props.video ||
    props.video.length <= 0
  ) {
    return null;
  }

  return makeVideo(props.video[0] as any);
};
