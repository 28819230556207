import { PageBuilderPageHash } from '@hurleymc/sdk/typings'
import * as React from 'react'
import styled from 'styled-components'

const Component = styled.div`
  display: block;
  width: 100%;
`

interface PageHashProps extends PageBuilderPageHash {
  className?: string
}

export default class extends React.Component<PageHashProps, {}> {
  componentDidMount () {
    this.scrollIntoView()
  }

  componentDidUpdate () {
    this.scrollIntoView()
  }

  scrollIntoView () {
    if (typeof document !== 'undefined' && typeof window !== 'undefined') {
      if (window.location && window.location.hash) {
        const element = document.getElementById(window.location.hash.replace(/^#+/g, ''))
        if (element) {
          setTimeout(() => element.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest'
          }), 500)
        }
      }
    }
  }

  render () {
    if (this.props === null || typeof this.props !== 'object') {
      return null
    }

    if (this.props.hash) {
      return (<Component id={this.props.hash} />)
    }

    return null
  }
}
