import * as React from 'react'
import styled from 'styled-components'

export default styled.label`
  color: #666;
  display: block;
  font-size: .8rem;
  letter-spacing: .5px;
  margin-bottom: .5rem;
`
