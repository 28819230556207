import {
  EntryInterface,
  ExternalLink
} from './typings'

export const selectEntryTitle = data => data && data.title ? data.title : ''

export const selectFirst = (data: any[]) => data && data.constructor === Array && data.length > 0 ? data[0] : {}

type LinkProps = {
  linkHash?: string
  linkText?: string
  to?: (EntryInterface[] | ExternalLink[])
}

export const selectLink = (data: LinkProps) => {
  if (data && data.linkText && data.to && data.to.length > 0) {
    const to: EntryInterface | ExternalLink = data.to[0]
    if ((to as ExternalLink).externalUrl) {
      if (data.linkHash) {
        return {
          href: `${(to as ExternalLink).externalUrl}/#${data.linkHash}`,
          title: data.linkText
        }
      } else {
        return {
          href: (to as ExternalLink).externalUrl,
          title: data.linkText,
          target: (to as ExternalLink).externalUrl.indexOf('http') === 0 ? '_blank' : null
        }
      }
    }
    if ((to as EntryInterface).uri) {
      if (data.linkHash) {
        return {
          title: data.linkText,
          to: `${(to as EntryInterface).uri}/#${data.linkHash}`
        }
      } else {
        return {
          title: data.linkText,
          to: (to as EntryInterface).uri
        }
      }
    }
  }
  return {}
}
