import React from "react";
import styled, { css } from "styled-components";
import { media, HurleyThemeProps } from "../../../styled";
import { Quicksand } from "../../Typography/TypographyFonts";
import { lighten } from "polished";

export interface RadioInputProps {
  label?: string;
  name: string;
  options: { label: string; value: any }[];
  register: any;
  onChange: any;
  required: boolean;
}

export const RadioGroup = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-flow: row wrap;
  margin-top: -1rem;
  margin-bottom: 1rem;

  .radio-container {
    margin-top: 1rem;
    position: relative;
    width: calc(50% - 0.5rem);
    &.no-padding {
      width: 50%;
    }
    &.full-width {
      width: 100%;
    }
    ${() =>
      media.breakpoint.up(
        "xl",
        css`
          &.one-fifth {
            width: calc(20% - 0.5rem);
            &.full-width {
              width: calc(20% - 0.5rem);
            }
          }
        `
      )}
  }

  .radio-button {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    margin: 0;
    cursor: pointer;

    &:checked,
    &:hover {
      + .radio-tile {
        background: ${({ theme }: HurleyThemeProps) => theme.colors.primary};
        border: 1px solid transparent;
        color: ${({ theme }: HurleyThemeProps) => theme.colors.primaryContrast};
        opacity: 1;
      }
    }
    &:hover:not(:checked) {
      + .radio-tile {
        background-color: ${({ theme }: HurleyThemeProps) =>
        lighten(0.15, theme.colors.primary)};
      }
    }
  }

  .radio-tile {
    background: ${({ theme }: HurleyThemeProps) => theme.colors.named.white};
    border: 1px solid ${({ theme }: HurleyThemeProps) => theme.colors.primary};
    color: ${({ theme }: HurleyThemeProps) => theme.colors.primary};
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-family: ${Quicksand.family};
    font-weight: ${Quicksand.weight.bold};
    font-size: 1rem;
    letter-spacing: 0.5px;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 0;
    opacity: 0.5;
    padding: 0.875rem 1rem;
    text-transform: uppercase;
    transition: all 0.25s ease-in-out;
  }
`;

export const StyledRadioInput = styled.div`
  .label {
    color: ${({ theme }: HurleyThemeProps) => theme.colors.named.doveGray};
    display: block;
    font-size: 1rem;
    font-family: ${Quicksand.family};
    font-weight: ${Quicksand.weight.medium};
    margin-bottom: 0.25rem;
  }
`;

export const RadioInput = ({
  label,
  name,
  options,
  register,
  onChange,
  required,
}: RadioInputProps) => (
  <StyledRadioInput>
    {label && <label className="label">{label}</label>}
    <RadioGroup className="control">
      {options.map((option, idx) => (
        <label
          className={`radio-container ${
            options.length === 2 ? "no-padding" : ""
          }${
            options.length > 4 ? "one-fifth" : ""
          } ${
            options.length % 2 !== 0 && idx === options.length - 1
              ? "full-width"
              : ""
          }`}
          key={`amount-${idx}`}
        >
          <input
            className="radio-button"
            type="radio"
            name={name}
            value={option.value}
            ref={register}
            onChange={onChange}
            required={required}
          />
          <div className="radio-tile">{option.label}</div>
        </label>
      ))}
    </RadioGroup>
  </StyledRadioInput>
);
