import { PageBuilderTable } from '@hurleymc/sdk/typings'

import * as React from 'react'

import { Col, Container, Row, Spacer, Table } from '../../../../components'

interface TableProps extends PageBuilderTable {
}

export default (props: TableProps) => {
  if (props.table) {
    const { columns, rows } = props.table
    return (
      <Spacer margin={{ xs: '6rem 1rem', lg: '8rem 1rem' }}>
        <Container>
          <Row xsJustifyContentCenter={true}>
            <Col lg={5 / 6}>
              <Table>
                <table>
                  <thead>
                    <tr>
                      {
                        columns.map(col => (
                          <th key={col.heading}>{col.heading}</th>
                        ))
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {
                      rows.map((row, key) => (
                        <tr key={key}>
                          {
                            row.data.map(data => (
                              <td key={data}>{data}</td>
                            ))
                          }
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </Table>
            </Col>
          </Row>
        </Container>
      </Spacer>
    )
  } else {
    return null
  }
}
