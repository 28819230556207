import * as React from 'react'

import {
  Col,
  Container,
  Row,
  Spacer,
  Typography
} from '../../../../components'

export default ({ header = null }) => {
  if (header) {
    return (
      <Spacer margin='2rem .5rem'>
        <Container>
          <Row xsJustifyContentCenter={true}>
            <Col lg={5 / 6}>
              <Typography variant='heading-2'>{header}</Typography>
            </Col>
          </Row>
        </Container>
      </Spacer>
    )
  } else {
    return null
  }
}
