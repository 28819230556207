import { Card, PageBuilderFeaturedTextWithDefaults } from '@hurleymc/sdk/typings'
import * as React from 'react'
import styled from 'styled-components'

import { CardList } from '../common'
import { Col, Container, Row, Spacer, Typography } from '../../../../components'

interface FeaturedTextWithDefaultsProps extends PageBuilderFeaturedTextWithDefaults {
  cards: Card[],
  className?: string
  components: { Link: (props: any) => JSX.Element }
}

const Center = styled.div`
  text-align: center;
`

export default (props: FeaturedTextWithDefaultsProps) => {
  if (props === null || typeof props !== 'object') {
    return null
  }

  const Link = props.components.Link

  return (
    <Spacer margin={{ xs: '6rem .5rem 1.5rem', lg: '8rem .5rem 1.5rem' }}>
      <Container>
        <Row xsJustifyContentCenter={true}>
          <Col md={5 / 6}>
            <Center>
              <Typography variant='heading-6' gutterBottom={true}>{props.identifier}</Typography>
              {
                props.summary &&
                props.summary.content &&
                <Typography component='div' dangerouslySetInnerHTML={{ __html: props.summary.content }} variant='body2' />
              }
            </Center>
          </Col>
        </Row>
      </Container>
      <Spacer margin={{ xs: '1.5rem .5rem 3rem', md: '1.5rem .5rem 6rem' }}>
      {
        props.cards && props.cards.length > 0 &&
        <CardList cards={props.cards as any} components={{ Link }} linksHidden={false} />
      }
      </Spacer>
    </Spacer>
  )
}
