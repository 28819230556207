import * as React from 'react'
import styled from 'styled-components'

const Radio = styled.input`
  display: block;
  width: 100%;
`

export default props => (
  <Radio
    type='radio'
    {...props}
  />
)
