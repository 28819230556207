import styled from "styled-components";
import { CardElement } from "@stripe/react-stripe-js";
import { HurleyThemeProps } from "../../../styled";

export const StyledCardElement = styled(CardElement)`
  /* color: #363636;
  background-color: white;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.05);
  padding-bottom: calc(0.75em - 1px);
  padding-left: calc(0.75em - 1px);
  padding-right: calc(0.75em - 1px);
  padding-top: calc(0.75em - 1px);
  max-height: 40px; */

  background-color: ${({ theme }: HurleyThemeProps) =>
    theme.colors.named.white};
  background-clip: padding-box;
  border: 1px solid
    ${({ theme }: HurleyThemeProps) => theme.colors.named.silver};
  border-radius: 0;
  color: ${({ theme }: HurleyThemeProps) => theme.colors.named.doveGray};
  display: block;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  margin-bottom: 1rem;
  outline: none;
  padding: 0.5rem 1rem;

  &.StripeElement--invalid {
    border-color: ${({ theme }: HurleyThemeProps) => theme.colors.named.mojo};
  }
`;
