import { selectFirst } from '@hurleymc/sdk/selectors'
import { Contact, ContactTypeCategory, PageBuilderContactDirectory, VolumeInterface } from '@hurleymc/sdk/typings'

import * as React from 'react'
import styled from 'styled-components'

import { AspectRatio, Col, Container, Icon, Picture, Row, Spacer, Typography } from '../../../../components'
import { theme, HurleyThemeProps } from '../../../../styled'

const Center = styled(Row)`
  display: block;
  margin: auto;
  text-align: center;
`

const Half = styled.div`
  margin: auto;
  width: 50%;
  padding-top: 2rem;
  padding-bottom: 2rem;
`

const Image = styled.div`
  border-radius: 50%;
  height: 100%;
  overflow: hidden;
  width: 100%;
`

const CardContainer = styled.div`
  height: 100%;
  padding-bottom: 1rem;
`

const Card = styled.div`
  border: 1px solid ${({ theme }: HurleyThemeProps) => theme.colors.named.silver};
  height: 100%;
`

const Bar = styled.div`
  height: 1px;
  background-color: ${({ theme }: HurleyThemeProps) => theme.colors.named.silver};
  width: 100%;
`

const TypeBox = styled.div`
  line-height: 0;
  text-align: center;
  margin-top: -1rem;
`

const Box = styled(Typography)`
  background-color: ${({ theme }: HurleyThemeProps) => theme.colors.named.curiousBlue};
  color: white;
  line-height: 0;
  padding: 1rem 1rem;
`

const VerticalAlignItems = styled.div`
  color: ${({ theme }: HurleyThemeProps) => theme.colors.named.curiousBlue};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 1rem;

  .material-icons {
    font-size: 1.25rem;
    margin-right: 1rem;
  }
`

const LinkText = styled(Typography)`
  line-height: 1.125;
`

interface ContactDirectoryProps extends PageBuilderContactDirectory {
  className?: string
  directory?: Contact[]
  showCategory?: boolean
}

export default (props: ContactDirectoryProps) => {
  if (props === null || typeof props !== 'object') {
    return null
  }

  return (
    <Container className={props.className}>
      <Spacer margin='4rem .5rem'>
        <Spacer margin='0 0 1.5rem'>
          <Center>
            <Typography variant='heading-6'>{props.identifier}</Typography>
          </Center>
        </Spacer>
        <Row xsJustifyContentCenter={true}>
          {Array.isArray(props.directory) &&
            props.directory.length > 0 &&
            props.directory.map((contact, key) => {
              const category: ContactTypeCategory = selectFirst(contact.contactType)
              const image: any = selectFirst(contact.contactImage)
              return (
                <Col xs={1} md={1 / 2} lg={1 / 3} xl={1 / 4} key={contact.id || key}>
                  <CardContainer>
                    <Card>
                      {image.url && (
                        <Half>
                          <AspectRatio ratio='1:1'>
                            <Image>
                              <Picture
                                alt={image.title}
                                sources={[
                                  // order matters here
                                  { media: `(min-width: ${theme.breakpoints.xl}px)`, srcSet: image.xl },
                                  { media: `(min-width: ${theme.breakpoints.lg}px)`, srcSet: image.lg },
                                  { media: `(min-width: ${theme.breakpoints.md}px)`, srcSet: image.md },
                                  { media: `(min-width: ${theme.breakpoints.sm}px)`, srcSet: image.sm }
                                ]}
                                src={image.xs || image.url}
                              />
                            </Image>
                          </AspectRatio>
                        </Half>
                      )}
                      <Bar />
                      {props.showCategory && category && category.title && (
                        <TypeBox>
                          <Box variant='button'>{category.title}</Box>
                        </TypeBox>
                      )}
                      <Spacer padding='1rem'>
                        <Spacer padding='0 0 1.5rem'>
                          <Typography variant='heading-4'>{contact.fullName}</Typography>
                          <Typography variant='heading-5'>{contact.jobTitle}</Typography>
                        </Spacer>
                        {contact.email && (
                          <VerticalAlignItems>
                            <Icon icon='email' />
                            <LinkText
                              color='inherit'
                              component='a'
                              dangerouslySetInnerHTML={{ __html: contact.email.split('@').join('<br/>@') }}
                              variant='button'
                              href={`mailto:${contact.email}`}
                            />
                          </VerticalAlignItems>
                        )}
                        {contact.phoneNumber && contact.phoneNumber.national && contact.phoneNumber.national !== '' && (
                          <VerticalAlignItems>
                            <Icon icon='phone' />
                            <LinkText color='inherit' component='a' variant='button' href={contact.phoneNumber.RFC3966}>
                              {contact.phoneNumber.national}
                            </LinkText>
                          </VerticalAlignItems>
                        )}
                      </Spacer>
                    </Card>
                  </CardContainer>
                </Col>
              )
            })}
        </Row>
      </Spacer>
    </Container>
  )
}
