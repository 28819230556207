import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { lighten } from "polished";

declare let Snipcart;

import { Quicksand } from "../Typography/TypographyFonts";
import { HurleyThemeProps } from "../../styled";

const StyledSnipcartCart = styled.div`
  align-items: center;
  background-color: ${({ theme }: HurleyThemeProps) => theme.colors.primary};
  background-image: linear-gradient(
    90deg,
    ${({ theme }: HurleyThemeProps) => theme.colors.primary},
    ${({ theme }: HurleyThemeProps) => lighten(0.05, theme.colors.primary)}
  );
  border-radius: 4px;
  color: ${({ theme }: HurleyThemeProps) => theme.colors.primaryContrast};
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  font-size: 1.5rem;
  justify-content: center;
  margin-top: -26px;
  padding: 0.5rem 1rem;
  position: fixed;
  right: 1rem;
  top: 50%;
  z-index: 10000;

  .snipcart-cart--shopping-bag {
  }
  .snipcart-cart--item-count {
    font-family: ${Quicksand.family};
    font-weight: ${Quicksand.weight.medium};
    margin-left: 0.5rem;
  }
`;

export const SnipcartCart = () => {
  if (typeof Snipcart === undefined) {
    return <></>;
  }
  const [numberOfItems, setNumberOfItems] = useState(0);
  const [showCart, setShowCart] = useState(false);
  useEffect(() => {
    let currentValue;
    function handleStatusChange() {
      let previousValue = currentValue;
      currentValue = Snipcart.store.getState();
      if (previousValue !== currentValue) {
        let totalItems = 0;
        try {
          totalItems = currentValue.cart.items.items
            .map((i) => i.quantity)
            .reduce((acc, curr) => (acc += curr), 0);
        } catch (e) {
          totalItems = 0;
        }
        setNumberOfItems(totalItems);
        setShowCart(totalItems > 0);
      }
    }
    const sub = Snipcart.store.subscribe(handleStatusChange);
    return function cleanup() {
      sub();
    };
  });
  if (!showCart) {
    return <></>;
  }
  return (
    <StyledSnipcartCart className="snipcart-checkout">
      <div className="snipcart-cart--shopping-bag">
        <i className="fas fa-shopping-bag" />
      </div>
      <div className="snipcart-cart--item-count">{numberOfItems}</div>
    </StyledSnipcartCart>
  );
};
