import * as React from 'react'
import styled, { css } from 'styled-components'
import { HurleyThemeProps } from '../../styled'

// https://github.com/twbs/bootstrap/blob/v4-dev/scss/_custom-forms.scss
const selectIndicator = `data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='#222' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E`.replace(
  '#',
  '%23'
)

const PsudoBorder = styled.div`
  background-color: ${({ theme }: HurleyThemeProps) => theme.colors.named.white};
  background-clip: padding-box;
  border: 1px solid ${({ theme }: HurleyThemeProps) => theme.colors.named.silver};
`

const Option = styled.option``
const Select = styled.select`
  appearance: none;
  background: #fff url("${selectIndicator}") no-repeat right .75rem center;
  background-size: 8px 10px;
  border: 0;
  border-radius: 0;
  color: #222;
  display: inline-block;
  font-size: .8rem;
  font-weight: 300;
  height: calc(2.25rem + 2px);
  line-height: 1.5;
  padding: .5rem 2rem .5rem .5rem;
  vertical-align: middle;
  width: 100%;

  ${Option}[value=""][disabled] {
    display: none;
  }

  ${({ value }) =>
    value
      ? ''
      : css`
          color: #666;
          font-weight: 300;
        `}

  &:not([size]):not([multiple]) {
    height: 2.25rem;
  }

  &:focus::-ms-value {
    color: #222;
    background-color: #fff;
  }
`

export default ({ input, label, options, placeholder, selected = null }) => {
  if (Array.isArray(options)) {
    return (
      <PsudoBorder>
        {label && <label htmlFor={label}>{label}</label>}
        <Select {...input}>
          {placeholder ? (
            <Option disabled={true} value=''>
              {placeholder}
            </Option>
          ) : selected ? null : (
            <Option disabled={true} value='' />
          )}
          {options &&
            options.length &&
            options.length > 0 &&
            options.map(option => (
              <Option key={option} value={option}>
                {option}
              </Option>
            ))}
        </Select>
      </PsudoBorder>
    )
  }

  if (options.constructor === Object) {
    return (
      <PsudoBorder>
        {label && <label htmlFor={label}>{label}</label>}
        <Select {...input}>
          {placeholder ? (
            <Option disabled={true} value=''>
              {placeholder}
            </Option>
          ) : selected ? null : (
            <Option disabled={true} value='' />
          )}
          {options &&
            Object.keys(options) &&
            Object.keys(options).length > 0 &&
            Object.keys(options).map(option => (
              <Option key={option} value={option}>
                {options[option]}
              </Option>
            ))}
        </Select>
      </PsudoBorder>
    )
  }

  return null
}
