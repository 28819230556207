import * as React from 'react'
import styled from 'styled-components'
import { darken, readableColor } from 'polished'
import { RedactorFieldData } from '@hurleymc/sdk/typings'

import { HurleyThemeProps } from '../../../../styled'
import { Col, Container, Row } from '../../../../components'
import { Redactor as OriginalRedactor } from '../common/Redactor'

interface InfoBoxProps {
  contentBlock: RedactorFieldData
  heading?: string
  style: 'default' | 'primary' | 'secondary'
}

const InfoBox = styled.div`
  background-color: ${({ theme }: HurleyThemeProps) => theme.colors.named.alabaster};
  border: 1px solid ${({ theme }: HurleyThemeProps) => theme.colors.named.gainsboro};
  padding: 2rem;
  &.primary {
    background-color: ${({ theme }: HurleyThemeProps) => theme.colors.primary};
    border-color: ${({ theme }: HurleyThemeProps) => darken(0.125, theme.colors.primary)};
    color: ${({ theme }: HurleyThemeProps) =>
      readableColor(theme.colors.primary, theme.colors.named.black, theme.colors.named.white)};
  }
  &.secondary {
  }
`

const Heading = styled.div`
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
`

const Redactor = styled(OriginalRedactor)`
  > :last-child {
    margin-bottom: 0;
  }
`

export const PageBuilderInfoBox = ({ contentBlock, heading, style }: InfoBoxProps) => (
  <Container>
    <Row xsJustifyContentCenter={true}>
      <Col lg={5 / 6}>
        <InfoBox className={style}>
          {heading && <Heading>{heading}</Heading>}
          <Redactor dangerouslySetInnerHTML={{ __html: contentBlock.content }} />
        </InfoBox>
      </Col>
    </Row>
  </Container>
)
