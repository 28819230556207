import React, { useState } from "react";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe, StripeCardElementOptions } from "@stripe/stripe-js";
import axios, { AxiosRequestConfig } from "axios";
import { useForm } from "react-hook-form";
import styled, { useTheme } from "styled-components";
import { Icon, Typography } from "..";
import { Quicksand } from "../Typography/TypographyFonts";

import {
  TextInput,
  EmailInput,
  MoneyInput,
  RadioInput,
  CardInput,
  CheckboxInput,
} from "./inputs";
import { HurleyThemeProps, Theme } from "../../styled";
import { Button } from "..";

export interface PaymentFormProps {
  amountSuggestions?: number[];
  buttonText?: string;
  content?: string;
  enableInHonorMemoryOf?: boolean;
  subheading?: string;
  metadata?: { [key: string]: string };
  productId: string;
  productName: string;
  stripeAccount: "foundation";
  successMessage?: string;
}

const FOUNDATION_STRIPE_PUBLISHABLE_KEY =
  process.env.GATSBY_FOUNDATION_STRIPE_PUBLISHABLE_KEY;
const HURLEY_API_KEY = process.env.GATSBY_HURLEY_API_KEY;
const HURLEY_API_URL = process.env.GATSBY_HURLEY_API_URL;
const STRIPE_ACCOUNTS = {
  foundation: FOUNDATION_STRIPE_PUBLISHABLE_KEY,
};

const getStripePublishableKey = (stripeAccount) => {
  return STRIPE_ACCOUNTS[stripeAccount];
};

const DEFAULT_NOTIFICATION_STATE = {
  open: false,
  message: "",
  type: "",
};

const createCardElementOptions = () => {
  const theme = useTheme() as Theme;
  const options: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: theme.colors.named.doveGray,
        color: theme.colors.named.doveGray,
        fontSize: "16px",
        lineHeight: "1.5",
        padding: 10,
        "::placeholder": {
          color: theme.colors.named.silver,
        },
        ":focus": {
          iconColor: theme.colors.named.doveGray,
        },
      },
      invalid: {
        iconColor: theme.colors.named.mojo,
        color: theme.colors.named.mojo,
      },
    },
  };
  return options;
};

const StyledAlert = styled.div`
  padding: 1rem;
  background: ${({ theme }: HurleyThemeProps) => theme.colors.named.eucalyptus};
  color: ${({ theme }: HurleyThemeProps) =>
    theme.colors.named.eucalyptusContrast};
  &.is-error {
    background: ${({ theme }: HurleyThemeProps) => theme.colors.named.mojo};
    color: ${({ theme }: HurleyThemeProps) => theme.colors.named.mojoContrast};
  }
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .icon {
    cursor: pointer;
    margin-left: 1rem;
  }
`;

const Alert = ({
  type,
  message,
  setNotificationState,
}: {
  type: string;
  message: string;
  setNotificationState?: Function;
}) => {
  const handleClick = () => {
    if (setNotificationState) {
      setNotificationState(DEFAULT_NOTIFICATION_STATE);
    }
  };
  return (
    <StyledAlert className={type === "error" ? "is-error" : "is-success"}>
      <span className="content" dangerouslySetInnerHTML={{ __html: message }} />
      {setNotificationState && (
        <span className="icon" onClick={handleClick}>
          <Icon className="icon-primary" icon="close" />
        </span>
      )}
    </StyledAlert>
  );
};

const Spinner = styled.i`
  margin-left: 0.5rem;
`;

const StyledPaymentForm = styled.div`
  .blurb {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
`;

const StyledButton = styled(Button)`
  font-family: ${Quicksand.family};
  font-weight: ${Quicksand.weight.bold};
  font-size: 1rem;
  padding: 0.875rem 1rem;
  text-transform: uppercase;
`;

const PaymentComponent = (props: PaymentFormProps) => {
  const {
    amountSuggestions,
    buttonText,
    enableInHonorMemoryOf,
    metadata,
    productId,
    productName,
    stripeAccount,
    successMessage,
  } = props;
  const amounts = amountSuggestions
    ? [
        ...amountSuggestions.map((a: number) => ({ value: a, label: `$${a}` })),
        { value: "", label: "Custom" },
      ]
    : [
        { value: "25", label: "$25" },
        { value: "50", label: "$50" },
        { value: "100", label: "$100" },
        { value: "500", label: "$500" },
        { value: "", label: "Custom" },
      ];
  const paymentButtonText = buttonText || "Pay";
  const defaultValues = {
    amount: String(amounts[0].value),
    customAmount: "",
    duration: "once",
    email: "",
    giveInHonorOf: false,
    giveInHonorOrMemoryOf: "inHonorOf",
    inHonorOrMemoryOf: "",
    name: "",
  };
  const {
    register,
    handleSubmit,
    watch,
    errors,
    getValues,
    reset,
    setValue,
  } = useForm({
    defaultValues,
    mode: "onBlur",
  });
  const [notificationState, setNotificationState] = useState(
    DEFAULT_NOTIFICATION_STATE
  );
  const [creditCardError, setCreditCardError] = useState();
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const watchAllValues = watch();
  const paymentAmount = watchAllValues.amount || watchAllValues.customAmount;

  const onSubmit = async () => {
    setLoading(true);
    setCreditCardError(null);
    setNotificationState(DEFAULT_NOTIFICATION_STATE);
    try {
      const card = elements.getElement(CardElement);
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card,
      });
      if (error) {
        throw error;
      }
      const data = {
        data: {
          ...getValues(),
          metadata,
          paymentMethod,
        },
        productId,
        productName,
        stripeAccount,
      };
      const options: AxiosRequestConfig = {
        headers: {
          "x-api-key": HURLEY_API_KEY,
        },
      };
      await axios.post(`${HURLEY_API_URL}/pay`, data, options);
      reset(defaultValues);
      card.clear();
      setNotificationState({
        open: true,
        message: successMessage || "Your payment was successfully processed!",
        type: "success",
      });
    } catch (error) {
      if (error.type === "validation_error") {
        setCreditCardError(error.message);
      } else {
        const message = "There was an error processing your payment.";
        // if (
        //   error.response &&
        //   error.response.data &&
        //   error.response.data.message
        // ) {
        //   message += `<br><small><em>${error.response.data.message}</em></small>`;
        // }
        setNotificationState({
          open: true,
          message,
          type: "error",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledPaymentForm>
      {notificationState.open && (
        <Alert
          type={notificationState.type}
          message={notificationState.message}
          setNotificationState={setNotificationState}
        />
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <RadioInput
          name="duration"
          options={[
            { value: "once", label: "Once" },
            { value: "monthly", label: "Monthly" },
          ]}
          register={register}
          required={true}
          onChange={(event) => {
            if (event.target.value) {
              setValue("amount", String(amounts[0].value));
            }
          }}
        />

        {watchAllValues.duration === "once" ? (
          <RadioInput
            label="Amount"
            name="amount"
            options={amounts}
            register={register}
            required={!watchAllValues.customAmount}
            onChange={(event) => {
              if (event.target.value) {
                setValue("customAmount", "");
              }
            }}
          />
        ) : (
          <RadioInput
            label="Amount"
            name="amount"
            options={amounts}
            register={register}
            required={!watchAllValues.customAmount}
            onChange={(event) => {
              if (event.target.value) {
                setValue("customAmount", "");
              }
            }}
          />
        )}

        {!watchAllValues.amount && (
          <MoneyInput
            label="Custom amount"
            name="customAmount"
            placeholder="Payment amount"
            register={register}
            required={!watchAllValues.amount}
            error={errors.customAmount ? "Amount is required" : undefined}
            onChange={(event) => {
              if (event.target.value) {
                setValue("amount", "");
              }
            }}
          />
        )}

        <TextInput
          label="Name"
          name="name"
          placeholder="Enter your name"
          register={register}
          required={true}
          error={errors.name ? "Name is required" : undefined}
        />

        <EmailInput
          label="Email"
          name="email"
          placeholder="Enter your email"
          register={register}
          required={true}
          error={errors.email ? "Email is required" : undefined}
        />

        {enableInHonorMemoryOf && (
          <CheckboxInput
            label="Give in honor or memory of someone"
            name="giveInHonorOf"
            register={register}
          />
        )}

        {watchAllValues.giveInHonorOf && (
          <>
            <RadioInput
              name="giveInHonorOrMemoryOf"
              options={[
                { value: "inHonorOf", label: "In honor of" },
                { value: "inMemoryOf", label: "In memory of" },
              ]}
              register={register}
              required={Boolean(watchAllValues.giveInHonorOf)}
              onChange={(event) => {}}
            />

            <TextInput
              name="inHonorOrMemoryOf"
              placeholder={`In ${
                watchAllValues.giveInHonorOrMemoryOf === "inMemoryOf"
                  ? "memory"
                  : "honor"
              } of`}
              register={register}
              required={Boolean(watchAllValues.giveInHonorOf)}
              error={
                errors.inHonorOrMemoryOf &&
                `In ${
                  watchAllValues.giveInHonorOrMemoryOf === "inMemoryOf"
                    ? "memory"
                    : "honor"
                } of is required`
              }
            />
          </>
        )}

        <CardInput label="Card Information" error={creditCardError} />

        {watchAllValues.duration === "monthly" && (
          <p className="blurb">
            You will be charged today, and each following month. You may
            cancel or change this amount at any time.
          </p>
        )}

        <StyledButton type="submit" disabled={loading}>
          {paymentButtonText} ${paymentAmount}
          {watchAllValues.duration === "monthly" && " Monthly"}
          {loading && <Spinner className="fas fa-circle-notch fa-spin" />}
        </StyledButton>
      </form>
    </StyledPaymentForm>
  );
};

export const PaymentForm = (props: PaymentFormProps) => {
  if (!props.stripeAccount || !getStripePublishableKey(props.stripeAccount)) {
    return (
      <Alert
        type={"error"}
        message={"There was a problem with the component configuration."}
      />
    );
  }
  const stripePromise = loadStripe(
    getStripePublishableKey(props.stripeAccount)
  );
  return (
    <Elements stripe={stripePromise}>
      <PaymentComponent {...props} />
    </Elements>
  );
};
