import { lighten } from "polished";
import { createGlobalStyle } from "../styled";
import { HurleyThemeProps } from "../styled/theme";

export default createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html {
    background: ${({ theme }: HurleyThemeProps) =>
      theme.components.html.background};
    font-family: sans-serif;
    line-height: 1.15;
    -ms-overflow-style: scrollbar;
    -ms-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-size-adjust: 100%;
  }

  @at-root {
    @-ms-viewport {
      width: device-width;
    }
  }

  article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
    display: block;
  }

  body {
    background: ${({ theme }: HurleyThemeProps) =>
      theme.components.body.background};
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    margin: 0;
    text-align: left;
  }

  [tabindex="-1"]:focus {
    outline: 0 !important;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: .5rem;
  }

  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  a {
    background-color: transparent;
    color: ${({ theme }: HurleyThemeProps) => theme.colors.default};
    text-decoration: none;
    transition: color 100ms ease-out;
    -webkit-text-decoration-skip: objects;

    &:hover {
      color: ${({ theme }: HurleyThemeProps) =>
        lighten(0.15, theme.colors.default)};
      text-decoration: none;
      transition: color 100ms ease-in;
    }

    &:not([href]):not([tabindex]) {
      color: inherit;
      text-decoration: none;

      &:hover,
      &:focus {
        color: inherit;
        text-decoration: none;
      }

      &:focus {
        outline: 0;
      }
    }
  }

  address {
    font-style: normal;
  }

  img {
    border-style: none;
    display: inline-block;
    max-width: 100%;
    vertical-align: middle;
  }

  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }

  pre {
    overflow: scroll;
    width: 100%;
  }

  html,
  body,
  #root {
    min-height: 100%;
    min-height: 100vh;
  }

  iframe {
    max-width: 100%;
    width: 100%;
  }

  .snipcart {
    .snipcart-modal__container {
      z-index: 10001;
    }
    .snipcart-cart-button--highlight,
    .snipcart__box--badge-highlight {
      background-color: ${({ theme }: HurleyThemeProps) =>
        theme.colors.primary};
      background-image: linear-gradient(
        90deg,
        ${({ theme }: HurleyThemeProps) => theme.colors.primary},
        ${({ theme }: HurleyThemeProps) => lighten(0.1, theme.colors.primary)}
      );
    }
    .snipcart__icon--blue-light,
      .snipcart__icon--blue-dark {
      path {
        fill: ${({ theme }: HurleyThemeProps) => theme.colors.primary};
      }
    }
    .snipcart__actions--link {
      color: ${({ theme }: HurleyThemeProps) => theme.colors.primary};
    }
  }
`;
