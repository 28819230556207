import { lighten, readableColor } from "polished";
import styled from 'styled-components';
import { HurleyThemeProps } from '../../../../styled';

/**
 * Global Survey Gizmo styles. Individual form styles are handled by the
 * Survey Gizmo service.
 */
export default styled.div`
  input[type='email'],
  input[type='text'] {
    max-width: 100%;
    border: 1px solid ${({ theme }: HurleyThemeProps) => theme.colors.named.silver} !important;
    border-radius: 0 !important;
  }

  select {
    max-width: 100%;
  }

  textarea {
    max-width: 100%;
    border: 1px solid ${({ theme }: HurleyThemeProps) => theme.colors.named.silver} !important;
    border-radius: 0 !important;
  }

  .sg-content {
    padding: 0 1rem 2rem !important;
  }

  .sg-group,
  .sg-group-item {
    max-width: 100%;
  }

  .sg-submit-button {

    background-color: ${({ theme }: HurleyThemeProps) => theme.colors.primary};
    color: ${({ theme }: HurleyThemeProps) =>
      readableColor(
        theme.colors.primary,
        theme.colors.named.white,
        theme.colors.named.black
      )};

    &:hover {
      background-color: ${({ theme }: HurleyThemeProps) =>
        lighten(0.15, theme.colors.primary)};
      color: ${({ theme }: HurleyThemeProps) =>
        readableColor(
          lighten(0.5, theme.colors.primary),
          theme.colors.named.white,
          theme.colors.named.black
        )};
    }

    &.disabled,
    &[disabled] {
      cursor: not-allowed;
      background-color: ${({ theme }: HurleyThemeProps) =>
        lighten(0.15, theme.colors.primary)};
      color: ${({ theme }: HurleyThemeProps) =>
        readableColor(
          lighten(0.5, theme.colors.primary),
          theme.colors.named.white,
          theme.colors.named.black
        )};
    }
  }
`
