import { selectLink } from '@hurleymc/sdk/selectors'

import { EntryInterface, ExternalLink, VolumeInterface, RedactorFieldData } from '@hurleymc/sdk/typings'

import * as React from 'react'
import styled, { css } from 'styled-components'

import { AspectRatio, Col, Container, Figure, Row, Spacer, Typography } from '../../../../components'
import { media, HurleyThemeProps } from '../../../../styled'

// https://stackoverflow.com/questions/21646738/convert-hex-to-rgba
function hexToRgbA (hex, opacity = 1) {
  let c
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('')
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = '0x' + c.join('')
    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')'
  }
  console.error('bad hex')
  return null
}

const Component = styled.div`
  display: block;
  position: relative;
  margin-bottom: 4rem;
  width: 100%;
  ${media.breakpoint.up(
    'lg',
    css`
      .lift-up {
        margin-top: -4rem;
      }
    `
  )}
`

const FigureContainer = styled(AspectRatio)`
  ${({ hiddenMobile }) =>
    hiddenMobile
      ? media.breakpoint.down(
          'lg',
          css`
            display: none;
          `
        )
      : null}
  ${media.breakpoint.up(
    'lg',
    css`
      display: block;
      margin-bottom: -8rem;
    `
  )}
`

const FigureBlock = styled.div`
  position: relative;
  background: radial-gradient(
    ellipse at center,
    ${({ theme }: HurleyThemeProps) => hexToRgbA(theme.colors.primary, 0)} 0%,
    ${({ theme }: HurleyThemeProps) => hexToRgbA(theme.colors.primary, 0.4)} 100%
  );
  margin-bottom: 2rem;

  ${media.breakpoint.up(
    'lg',
    css`
      background: none;
      height: 100%;
    `
  )}
`

const FigureImage = styled(Figure)`
  display: none;
  ${media.breakpoint.up(
    'lg',
    css`
      display: block;
    `
  )}
`

const StoryStatement = styled.div`
  align-items: flex-start;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 4rem 1rem;

  ${media.breakpoint.up(
    'lg',
    css`
      /* align-items: flex-end; */
      bottom: 0;
      margin-bottom: 8rem;
      padding-right: 2rem;
      position: absolute;
      right: 0;
      top: 0;
      width: 50%;
    `
  )}
`

const SummaryBox = styled.div`
  margin: 2rem 1rem;

  ${media.breakpoint.up(
    'lg',
    css`
      align-items: center;
      background-color: white;
      border: 1px solid ${({ theme }: HurleyThemeProps) => theme.colors.named.silver};
      display: flex;
      justify-content: row;
      min-height: 16rem;
      padding: 2rem;
      margin: 0;
    `
  )}
`

const StyledHeading1 = styled(Typography)`
  /* ${media.breakpoint.up(
    'lg',
    css`
      text-align: right;
    `
  )} */
`

const LinkBox = styled.div`
  display: block;
  font-weight: 700;
  margin-top: 1rem;
  a {
    display: flex;
    text-transform: uppercase;
  }
`

type StoryWithSummary = {
  components: { Link: (props: any) => JSX.Element };
  header: string;
  image: VolumeInterface[];
  linkText: string;
  storyStatement: string;
  storyStatementColor?: string;
  summary: RedactorFieldData;
  to: EntryInterface[] | ExternalLink[];
}

export default (props: StoryWithSummary) => {
  const Link = props.components.Link
  const linkProps = selectLink(props)

  return (
    <Component>
      {(props.storyStatement || linkProps || (props.image && props.image.length > 0)) && (
        <FigureContainer xs={false} lg={true} ratio='9:4' hiddenMobile={!props.storyStatement}>
          <FigureBlock>
            {props.image && props.image.length > 0 && <FigureImage {...Object.assign(props.image[0], { url: null })} />}
            {(props.storyStatement || linkProps) && (
              <StoryStatement>
                {props.storyStatement && (
                  <StyledHeading1 variant='heading-1' color={props.storyStatementColor}>
                    {props.storyStatement}
                  </StyledHeading1>
                )}
                {linkProps && linkProps.title && (
                  <LinkBox>
                    <Link {...linkProps}>{linkProps.title}</Link>
                    <i className='material-icons'>chevron_right</i>
                  </LinkBox>
                )}
              </StoryStatement>
            )}
          </FigureBlock>
        </FigureContainer>
      )}
      {props.summary && (
        <Spacer>
          <Container>
            <Row xsJustifyContentCenter={true}>
              <Col lg={5 / 6}>
                <SummaryBox>
                  <div>
                    <Typography gutterBottom={true} variant='heading-2'>
                      {props.header}
                    </Typography>
                    <Typography component='div' dangerouslySetInnerHTML={{ __html: props.summary }} variant='body' />
                  </div>
                </SummaryBox>
              </Col>
            </Row>
          </Container>
        </Spacer>
      )}
    </Component>
  )
}
