import * as React from 'react'
import styled from 'styled-components'

const ITag = styled.i``

type IconProps = {
  className?: string
  icon: string
  style?: any
}

const Icon = ({ className = '', icon, ...props }: IconProps) => {

  if (/^fa/.test(icon)) {
    const faIcon = icon.replace(/([a-z])([A-Z])/g, '$1-$2').replace(/\s+/g, '-').toLowerCase()
    return (<ITag {...props} className={[className, 'fas', faIcon].join(' ').trim()} />)
  }

  return (
    <ITag {...props} className={[className, 'material-icons'].join(' ').trim()}>{icon}</ITag>
  )
}

export default styled(Icon)``
