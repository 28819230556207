import { selectLink } from "@hurleymc/sdk/selectors";

import { Cta, PageBuilderCallToAction } from "@hurleymc/sdk/typings";

import * as React from "react";
import styled, { css } from "styled-components";
import { darken } from "polished";

import {
  Row,
  Container,
  Col,
  Spacer,
  Typography,
} from "../../../../components";
import { media, HurleyThemeProps } from "../../../../styled";

const Component = styled.div`
  background-color: ${({ theme }: any) => theme.colors.primary};
  color: white;
`;

const LinkWrapper = styled.span`
  a {
    background-color: ${({ theme }: HurleyThemeProps) =>
      theme.colors.primaryContrast};
    color: ${({ theme }: HurleyThemeProps) => theme.colors.primary};
    display: block;
    padding: 0.75rem 1.5rem;
    text-align: center;

    &:hover {
      background-color: ${({ theme }: any) =>
        darken(0.05, theme.colors.primaryContrast)};
      color: ${({ theme }: HurleyThemeProps) => theme.colors.primary};
    }

    ${media.breakpoint.up(
      "md",
      css`
        display: inline-block;
      `
    )}
  }
`;

interface CallToActionProps extends PageBuilderCallToAction {
  className?: string;
  components: { Link: (props: any) => JSX.Element };
  cta: Cta[];
}

export default (props: CallToActionProps) => {
  if (props === null || typeof props !== "object") {
    return null;
  }

  if (props.cta && props.cta.length > 0) {
    const cta = props.cta[0];

    const { Link } = props.components;
    const linkProps = selectLink(props.cta[0]);

    return (
      <Component>
        <Container>
          <Row xsJustifyContentCenter={true}>
            <Col lg={5 / 6}>
              <Spacer padding="4rem .5rem">
                {cta.header && (
                  <Typography
                    color="inherit"
                    component="h3"
                    gutterBottom={true}
                    variant="heading-2"
                  >
                    {cta.header}
                  </Typography>
                )}
                {cta.summary && cta.summary.content && (
                  <Spacer margin="0 0 2rem">
                    <Typography
                      color="inherit"
                      component="div"
                      dangerouslySetInnerHTML={{ __html: cta.summary.content }}
                      variant="body"
                    />
                  </Spacer>
                )}
                {linkProps && linkProps.title && (
                  <LinkWrapper>
                    <Link {...linkProps}>
                      <Typography color="inherit" variant="button">
                        {linkProps.title}
                      </Typography>
                    </Link>
                  </LinkWrapper>
                )}
              </Spacer>
            </Col>
          </Row>
        </Container>
      </Component>
    );
  }

  return null;
};
