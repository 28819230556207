import React, { useState, useEffect, useRef } from 'react'
import classnames from 'classnames'
import { lighten, darken, readableColor, rgba } from 'polished'
import styled, { css } from 'styled-components'

import { HurleyThemeProps, media } from '../../styled'
import Collapse from '../Collapse'

type SubnavEntry = {
  id: number;
  level: number;
  title: string;
  uri: string;
  linkUrl: string;
  linkType: string;
  linkTarget: string;
}

type SubnavEntryWithChildren = SubnavEntry & {
  children: SubnavEntryWithChildren[];
}

type SubnavComponents = {
  Link: any;
}

export type SubnavProps = {
  entries: SubnavEntryWithChildren[];
  current: SubnavEntry;
  components: SubnavComponents;
}

type SubnavMenuProps = SubnavProps & {
  isSubmenu?: boolean;
  level: number;
  lastSubnav?: any;
}

type SubnavMenuItemProps = {
  entry: SubnavEntryWithChildren;
  current: SubnavEntry;
  components: SubnavComponents;
  level: number;
  last: boolean;
}

type CustomLinkProps = {
  current: SubnavEntry;
  entry: SubnavEntryWithChildren;
  components: SubnavComponents;
  children: any;
}

const CustomLink = ({ current, entry, components, children }: CustomLinkProps) => {
  const { Link } = components
  const className = classnames({ active: current.uri === entry.uri, 'link-page': entry.linkUrl })
  if (entry.linkUrl) {
    switch (entry.linkType) {
      case 'internal':
        return (
          <Link to={entry.linkUrl} className={className}>
            {children}
          </Link>
        )
      default:
        return (
          <Link href={entry.linkUrl} target={entry.linkTarget} className={className}>
            {children}
          </Link>
        )
    }
  }
  return (
    <Link className={className} to={entry.uri}>
      {children}
    </Link>
  )
}

const SubnavMenuItem = ({ current, entry, components, level, last }: SubnavMenuItemProps) => {
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false)
  const hasChildren = entry.children && entry.children.length > 0
  const handleClick = () => setIsSubmenuOpen(!isSubmenuOpen)
  const itemClassName = classnames('subnav--menu--item', {
    open: isSubmenuOpen,
    'has-children': hasChildren,
    last: last
  })
  const headerClassName = classnames('subnav--menu--item--header', { last: last })

  return (
    <li className={itemClassName}>
      <div className={headerClassName}>
        <div className='subnav--menu--item--header--link'>
          <CustomLink current={current} entry={entry} components={components}>
            {entry.title}
          </CustomLink>
        </div>
        {hasChildren && (
          <i className='subnav--open--icon material-icons' onClick={handleClick}>
            chevron_right
          </i>
        )}
      </div>
      {hasChildren && (
        <Collapse open={isSubmenuOpen}>
          <SubnavMenu
            current={current}
            entries={entry.children}
            components={components}
            isSubmenu={true}
            level={level + 1}
          />
        </Collapse>
      )}
    </li>
  )
}

const SubnavExtraMenu = ({ current, lastSubnav, components }) => {
  const { items } = lastSubnav
  return (
    <ul className='subnav--extra--menu'>
      {items &&
        items.length &&
        items.map((entry, idx) => {
          const className = classnames('subnav--extra--menu--item', {
            last: idx === items.length - 1
          })
          return (
            <li className={className} key={`subnav--extra--menu--item-${idx}`}>
              <CustomLink current={current} entry={entry} components={components}>
                {entry.title}
              </CustomLink>
            </li>
          )
        })}
    </ul>
  )
}

const SubnavMenu = ({ current, entries, components, level, lastSubnav }: SubnavMenuProps) => {
  return (
    <div className={`subnav--menu--wrapper`}>
      <ul className={`subnav--menu level-${level}`}>
        {entries.map((entry, idx) => (
          <SubnavMenuItem
            key={`subnav--menu--${idx}`}
            current={current}
            entry={entry}
            components={components}
            level={level}
            last={idx === entries.length - 1}
          />
        ))}
      </ul>
      {current && current.level && current.level >= 3 && lastSubnav && (
        <SubnavExtraMenu lastSubnav={lastSubnav} current={current} components={components} />
      )}
    </div>
  )
}

const SubnavWrapper = styled.div`
  text-transform: uppercase;
  .subnav--desktop {
    display: none;
  }
  .subnav--mobile {
    display: block;
  }
  ${media.breakpoint.up(
    'lg',
    css`
      font-size: 0.875rem;
      .subnav--desktop {
        display: flex;
      }
      .subnav--mobile {
        display: none;
      }
    `
  )}

  background: ${({ theme }: HurleyThemeProps) => theme.colors.named.doveGray};
  .subnav--header {
    background: ${({ theme }: HurleyThemeProps) => theme.colors.named.black};
    color: ${({ theme }: HurleyThemeProps) =>
      readableColor(theme.colors.named.black, theme.colors.named.black, theme.colors.named.white)};
    transition: all 150ms ease-in-out;
    &:hover {
      background: ${({ theme }: HurleyThemeProps) => lighten(0.125, theme.colors.named.black)};
    }
    &.primary {
      background: ${({ theme }: HurleyThemeProps) => theme.colors.primary};
      color: ${({ theme }: HurleyThemeProps) =>
        readableColor(theme.colors.primary, theme.colors.named.black, theme.colors.named.white)};
      &:hover {
        background: ${({ theme }: HurleyThemeProps) => lighten(0.05, theme.colors.primary)};
      }
    }
    &.subnav--2 {
      background: ${({ theme }: HurleyThemeProps) => theme.colors.named.charcoal};
      color: ${({ theme }: HurleyThemeProps) =>
        readableColor(theme.colors.named.charcoal, theme.colors.named.black, theme.colors.named.white)};
      &:hover {
        background: ${({ theme }: HurleyThemeProps) => lighten(0.05, theme.colors.named.charcoal)};
      }
    }
    cursor: pointer;
    padding: 1rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    &.open {
      .subnav--open--icon {
        transform: rotate(-90deg);
      }
    }
  }
  .subnav--header--text {
    ${media.breakpoint.up(
      'lg',
      css`
        padding: 0 2rem;
      `
    )}
  }
  .subnav--dropdown {
    position: absolute;
    overflow-y: auto;
    overflow-x: hidden;
    outline: 0;
    width: 100%;
    transition: box-shadow 150ms ease-in-out;
    z-index: 1;
    &.open {
      box-shadow:  0 10px 10px -10px ${({ theme }: HurleyThemeProps) => rgba(theme.colors.named.black, 0.5)};
    }
    ${media.breakpoint.up(
      'lg',
      css`
        width: auto;
      `
    )}
  }
  .subnav--menu--item {
    overflow: hidden;
    &.has-children {
      .subnav--menu {
        /* padding-left: 1rem; */
        /* background: ${({ theme }: HurleyThemeProps) => theme.colors.named.mercury}; */
      }
    }
    &.open {
      > .subnav--menu--item--header {
        /* a {
          color: ${({ theme }: HurleyThemeProps) => darken(0.1, theme.colors.primary)};
        } */
        > .subnav--open--icon {
          transform: rotate(-90deg);
        }
      }
    }
  }
  .subnav--menu--item--header {
    margin: 1rem 1rem 0;
    padding-bottom: 1rem;
    border-bottom: 1px solid ${({ theme }: HurleyThemeProps) => theme.colors.named.silver};
    &.last {
      border-bottom: none;
    }
    display: flex;
    justify-content: stretch;
    align-items: center;

    > .subnav--menu--item--header--link {
      flex-grow: 1;
      a {
        display: block;
      }
      ${media.breakpoint.up(
        'lg',
        css`
          padding: 0 2rem;
        `
      )}
    }
  }
  .subnav--open--icon {
    cursor: pointer;
    transform: rotate(90deg);
    transition: all 150ms ease-in-out;
    font-size: 2rem;
  }
  .subnav--menu {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    &.level-0 {
      background: ${({ theme }: HurleyThemeProps) => theme.colors.named.alabaster};
      border: 1px solid ${({ theme }: HurleyThemeProps) => theme.colors.named.silver};
      font-size: 0.875rem;
    }
    &.level-1 {
      font-size: 0.875rem;
    }
    &.level-2 {
      font-size: 0.75rem;
    }
    &.level-3 {
      font-size: 0.625rem;
    }
    &.level-4 {
      font-size: 0.5rem;
    }
  }
  .subnav--menu--rest {
    list-style: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 0 auto;
    padding: 0 1rem;
    white-space: nowrap;
    a {
      border-radius: 0.25rem;
      padding: 0.5rem 1rem;
      color: ${({ theme }: HurleyThemeProps) =>
        readableColor(theme.colors.named.doveGray, theme.colors.named.black, theme.colors.named.white)};
      display: block;
      transition: all 150ms ease-in-out;
      &.active {
        background: ${({ theme }: HurleyThemeProps) => darken(0.1, theme.colors.named.doveGray)};
        color: ${({ theme }: HurleyThemeProps) =>
          readableColor(darken(0.1, theme.colors.named.doveGray), theme.colors.named.black, theme.colors.named.white)};
      }
      &:hover {
        background: ${({ theme }: HurleyThemeProps) => lighten(0.05, theme.colors.named.doveGray)};
        color: ${({ theme }: HurleyThemeProps) =>
          readableColor(
            lighten(0.05, theme.colors.named.doveGray),
            theme.colors.named.black,
            theme.colors.named.white
          )};
      }
    }
  }

  .subnav--extra--menu {
    background: ${({ theme }: HurleyThemeProps) => lighten(0.05, theme.colors.named.doveGray)};
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .subnav--extra--menu--item {
    padding: 1rem 1rem;
    border-bottom: 1px solid ${({ theme }: HurleyThemeProps) => theme.colors.named.silver};
    &.last {
      border-bottom: none;
    }
    a {
      color: ${({ theme }: HurleyThemeProps) =>
        readableColor(theme.colors.named.doveGray, theme.colors.named.black, theme.colors.named.white)};
    }
  }
`

type SubnavDropdownProps = {
  children: any;
  title: string;
  idx: number;
}

const SubnavDropdown = ({ children, title, idx }: SubnavDropdownProps) => {
  const [width, setWidth] = useState(0)
  const headerRef = useRef(null)
  useEffect(() => {
    const dropdownWidth = headerRef && headerRef.current && headerRef.current.offsetWidth
    setWidth(dropdownWidth)
  })

  const wrapperRef = useRef(null)
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
  const handleHeaderClick = () => setDropdownIsOpen(!dropdownIsOpen)

  const handleDocumentClick = event => {
    if (
      dropdownIsOpen &&
      wrapperRef.current &&
      (!wrapperRef.current.contains(event.target) || event.target.tagName.toLowerCase() === 'a')
    ) {
      setDropdownIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick, false)
    return () => {
      document.removeEventListener('click', handleDocumentClick, false)
    }
  }, [dropdownIsOpen])

  const headerClassName = classnames('subnav--header', `subnav--${idx}`, { open: dropdownIsOpen, primary: idx === 0 })
  const dropdownClassName = classnames('subnav--dropdown', { open: dropdownIsOpen })
  return (
    <>
      <div className={headerClassName} onClick={handleHeaderClick} ref={headerRef}>
        <div className='subnav--header--text'>{title}</div>
        <i className='subnav--open--icon material-icons'>chevron_right</i>
      </div>
      <div className={dropdownClassName} ref={wrapperRef} style={{ width }}>
        <Collapse open={dropdownIsOpen}>{children}</Collapse>
      </div>
    </>
  )
}

const BreadcrumbsWrapper = styled.div`
  display: none;
  align-items: center;
  font-size: 0.75rem;
  padding: 0.5rem 1rem;
  .breadcrumb--divider {
    margin: 0 0.5rem;
  }
  ${media.breakpoint.up(
    'lg',
    css`
      display: flex;
    `
  )}
`

const BreadcumbsItem = styled.div`
  display: flex;
  align-items: center;
`

const getBreadcrumbs = (current, entries, breadcrumbs = []) => {
  entries.forEach(entry => {
    if (current.uri.indexOf(entry.uri) > -1) {
      breadcrumbs.push(entry)
      if (entry.children && entry.children.length > 0) {
        breadcrumbs = getBreadcrumbs(current, entry.children, breadcrumbs)
      }
    }
  })
  return breadcrumbs
}

const Breadcrumbs = ({ current, entries, components }) => {
  const { Link } = components
  const breadcrumbs = getBreadcrumbs(current, entries, [
    {
      uri: '/',
      title: 'Home'
    }
  ])
  return (
    <BreadcrumbsWrapper>
      {breadcrumbs &&
        breadcrumbs.length > 0 &&
        breadcrumbs.map((breadcrumb, idx) => {
          if (idx < breadcrumbs.length - 1) {
            return (
              <BreadcumbsItem key={`breadcrumbs--item--${idx}`}>
                <Link to={breadcrumb.uri}>{breadcrumb.title}</Link>
                <div className='breadcrumb--divider'>/ </div>
              </BreadcumbsItem>
            )
          }
          return <BreadcumbsItem key={`breadcrumbs--item--${idx}`}>{breadcrumb.title}</BreadcumbsItem>
        })}
    </BreadcrumbsWrapper>
  )
}

export const Subnav = ({ current, entries, components }: SubnavProps) => {
  const headerEntry = entries.filter(entry => current.uri.indexOf(entry.uri) === 0)
  if (!headerEntry.length) {
    return null
  }

  const { children, title } = headerEntry[0]

  const subNavs = []
  const level2 = entries.filter(entry => current.uri.indexOf(entry.uri) > -1)[0]
  if (level2 && level2.children.length > 0) {
    subNavs.push({
      header: level2,
      items: level2.children
    })
    const level3 = level2.children.filter(entry => current.uri.indexOf(entry.uri) > -1)[0]
    if (level3 && level3.children.length > 0) {
      subNavs.push({
        header: level3,
        items: level3.children
      })
      const level4 = level3.children.filter(entry => current.uri.indexOf(entry.uri) > -1)[0]
      if (level4 && level4.children.length > 0) {
        subNavs.push({
          header: level4,
          items: level4.children
        })
        const level5 = level4.children.filter(entry => current.uri.indexOf(entry.uri) > -1)[0]
        if (level5 && level5.children.length > 0) {
          subNavs.push({
            header: level5,
            items: level5.children
          })
        }
      }
    }
  }

  const lastSubnav = subNavs[subNavs.length - 1]

  return (
    <>
      <SubnavWrapper>
        <div className='subnav--mobile'>
          <SubnavDropdown title={title} idx={0}>
            <SubnavMenu
              current={current}
              entries={children}
              components={components}
              level={0}
              lastSubnav={lastSubnav}
            />
          </SubnavDropdown>
        </div>
        <div className='subnav--desktop'>
          {subNavs &&
            subNavs.length > 0 &&
            subNavs.map((subnav, idx) => {
              if (idx < 2 || idx < subNavs.length - 1) {
                return (
                  <div key={`subnav--dropdown--${idx}`}>
                    <SubnavDropdown title={subnav.header.title} idx={idx}>
                      <ul className={`subnav--menu level-0`}>
                        {subnav.items.map((entry, entryIdx) => {
                          const hasChildren = entry.children && entry.children.length > 0
                          const headerClassName = classnames('subnav--menu--item--header', {
                            last: entryIdx === subnav.items - 1
                          })
                          const className = classnames('subnav--menu--item', { 'has-children': hasChildren })
                          return (
                            <li key={`subnav--menu--item--${idx}--${entryIdx}`} className={className}>
                              <div className={headerClassName}>
                                <div className='subnav--menu--item--header--link'>
                                  <CustomLink current={current} entry={entry} components={components}>
                                    {entry.title}
                                  </CustomLink>
                                </div>
                                {hasChildren && <i className='material-icons'>chevron_right</i>}
                              </div>
                            </li>
                          )
                        })}
                      </ul>
                    </SubnavDropdown>
                  </div>
                )
              } else {
                return (
                  <ul className='subnav--menu--rest' key={`subnav--menu--rest--${idx}`}>
                    {subnav.items.map((entry, entryIdx) => {
                      return (
                        <li key={`subnav--menu--item--${idx}==${entryIdx}`}>
                          <CustomLink current={current} entry={entry} components={components}>
                            {entry.title}
                          </CustomLink>
                        </li>
                      )
                    })}
                  </ul>
                )
              }
            })}
        </div>
      </SubnavWrapper>
      <Breadcrumbs current={current} entries={entries} components={components} />
    </>
  )
}
