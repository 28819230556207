import { VolumeInterface } from '@hurleymc/sdk/typings'

import * as React from 'react'
import styled, { css } from 'styled-components'

import { AspectRatio, Figure } from '../../../../components'
import { media } from '../../../../styled'

const FigureBlock = styled(AspectRatio)`
  display: none;
  margin-bottom: 4rem;

  ${media.breakpoint.up('lg', css`
    display: inherit;
  `)}
`

type SimpleStory = {
  image: VolumeInterface[]
}

export default (props: SimpleStory) => {
  return (
    <FigureBlock ratio='9:4'>
    {
      props.image &&
      props.image.length > 0 &&
      <Figure {...Object.assign(props.image[0], { url: null })} />
    }
    </FigureBlock>
  )
}
