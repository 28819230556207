import {
  VolumeInterface, ImagesVolume
} from '@hurleymc/sdk/typings'

import * as React from 'react'
import styled from 'styled-components'

import {
  Col,
  Container,
  Picture,
  Row,
  Spacer,
  Typography
} from '../../../../components'
import { theme } from '../../../../styled'

const FlexBox = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: nowrap;
`

interface FeaturedContentProps {
  className?: string
  components?: {
    Link?: (props: any) => JSX.Element
  }
  header: string
  html: string
  identifier: string
  image?: VolumeInterface[]
  video?: VolumeInterface[]
}

interface Image extends ImagesVolume {
  xs?: string
  sm?: string
  md?: string
  lg?: string
  xl?: string
}

export default (props: FeaturedContentProps) => {
  if (props === null || typeof props !== 'object') {
    return null
  }

  const image = props.image && props.image.length > 0 ? (props.image[0] as Image) : null

  return (
    <Spacer margin='4rem .5rem'>
      <Container>
        <Row xsJustifyContentCenter={true}>
          <Col lg={5 / 6}>
            <Row>
              <Col md={5 / 12}>
                <FlexBox>
                  <Typography variant='heading-6' gutterBottom={true}>{props.identifier}</Typography>
                  <Typography variant='heading-2' gutterBottom={true}>{props.header}</Typography>
                  {
                    props.html &&
                    <Typography
                      component='div'
                      dangerouslySetInnerHTML={{ __html: props.html }}
                      variant='body'
                    />
                  }
                </FlexBox>
              </Col>
              <Col md={7 / 12}>
                {
                  image &&
                  <Spacer margin={{ xs: '0 0 4rem', md: '0' }}>
                    <Picture
                      alt={image.title}
                      maxHeight='32rem'
                      sources={[
                        // order matters here
                        { media: `(min-width: ${theme.breakpoints.xl}px)`, srcSet: image.xl },
                        { media: `(min-width: ${theme.breakpoints.lg}px)`, srcSet: image.lg },
                        { media: `(min-width: ${theme.breakpoints.md}px)`, srcSet: image.md },
                        { media: `(min-width: ${theme.breakpoints.sm}px)`, srcSet: image.sm }
                      ]}
                      src={image.xs || image.url}
                    />
                  </Spacer>
                }
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Spacer>
  )
}
