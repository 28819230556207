import * as React from 'react'
export default class RadioGroup extends React.Component<any, any> {
  private radios = []

  handleRadioChange = (event, checked) => {
    if (checked && this.props.onChange) {
      this.props.onChange(event, event.target.value)
    }
  }

  render () {
    const { children, name, value, onChange, ...props } = this.props

    this.radios = []
    const radios = this.radios

    return (
      <div>
        {
          React.Children.map(children, (child: React.ReactElement<{ value: string}>, index) => {
            if (!React.isValidElement(child)) {
              return null
            }

            return React.cloneElement(child, {
              key: index,
              name
              // inputRef: node => {
              //   console.log('node', node)
              //   if (node) {
              //     radios.push(node)
              //   }
              // },
              // checked: (value === child.props.value),
              // onChange: this.handleRadioChange
            } as any)
          })}
          <pre>{JSON.stringify(radios)}</pre>
      </div>
    )
  }
}
