import { selectLink } from "@hurleymc/sdk/selectors";
import {
  Card,
  PageBuilderDefaultContent,
  EntryInterface,
  ExternalLink,
} from "@hurleymc/sdk/typings";
import * as React from "react";

import { CardList, ChevyLink } from "../common";
import { Container, Row, Spacer, Typography } from "../../../../components";

interface DefaultContentProps extends PageBuilderDefaultContent {
  cards: Card[];
  className?: string;
  components: { Link: (props: any) => JSX.Element };
  linkText?: string;
  linkHash?: string;
  to?: EntryInterface[] | ExternalLink[];
}

export default (props: DefaultContentProps) => {
  if (props === null || typeof props !== "object") {
    return null;
  }

  const Link = props.components.Link;
  const linkProps = selectLink({
    linkText: props.linkText,
    linkHash: props.linkHash,
    to: props.to,
  });

  const hasOverrideLink = Object.keys(linkProps).length > 0;

  return (
    <Spacer margin={{ xs: "6rem .5rem", lg: "8rem .5rem" }}>
      <Container className={props.className}>
        {props.identifier && (
          <Row xsJustifyContentCenter={true}>
            <Spacer margin="0 0 2rem">
              <Typography variant="heading-6">{props.identifier}</Typography>
            </Spacer>
          </Row>
        )}
        {props.cards && props.cards.length > 0 && (
          <CardList cards={props.cards as any} components={{ Link }} />
        )}
        {hasOverrideLink && (
          <Row xsJustifyContentCenter={true}>
            <ChevyLink
              align="center"
              color="default"
              component={Link}
              {...linkProps}
            >
              {props.linkText}
            </ChevyLink>
          </Row>
        )}
      </Container>
    </Spacer>
  );
};
