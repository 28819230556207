import styled from 'styled-components'

import desktopSiteBar from './styles/desktop-site-bar'
import nav from './styles/nav'
import navbar from './styles/navbar'

export default styled.div`
  position: relative;
  width: 100%;
  user-select: none;
  z-index: 9999;

  ${desktopSiteBar}
  ${navbar}
  ${nav}
`
