import React from "react"
import { Provider } from "react-redux"

import "./src/scss/app.scss"
import createStore from "./src/lib/state/create-store"

export default ({ element }) => {
  const store = createStore()
  return <Provider store={store}>{element}</Provider>
}
