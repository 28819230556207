import { PageBuilderStatistics, Statistic } from "@hurleymc/sdk/typings";

import * as React from "react";
import styled from "styled-components";

import {
  Col,
  Container,
  Row,
  Spacer,
  Typography,
  Icon
} from "../../../../components";

const Component = styled.div``;
const Identifier = styled(Typography)`
  text-align: center;
`;

const Stat = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
`;

const Header = styled(Typography)`
  color: ${({ theme }: any) => theme.colors.primary};
  font-weight: bold;
`;

const StyledIcon = styled(Icon)`
  color: ${({ theme }: any) => theme.colors.primary};
  font-size: 2rem;
  margin-bottom: 1rem;
`;

const TextBox = styled.div`
  text-align: center;
`;

interface StatisticsProps extends PageBuilderStatistics {
  className?: string;
  components: { Link: (props: any) => JSX.Element };
  statistics?: Statistic[];
}

export default (props: StatisticsProps) => {
  if (props === null || typeof props !== "object") {
    return null;
  }

  return (
    <Component>
      <Spacer margin={{ xs: "6rem .5rem 4rem", lg: "8rem .5rem 6rem" }}>
        {props.identifier && (
          <Spacer margin="0 0 2rem">
            <Identifier variant="heading-6" gutterBottom={true}>
              {props.identifier}
            </Identifier>
          </Spacer>
        )}
        <Container>
          <Row xsJustifyContentCenter={true}>
            {props.statistics &&
              props.statistics.length > 0 &&
              props.statistics
                .filter(statistic => {
                  const flag =
                    statistic.icon &&
                    statistic.header &&
                    statistic.summary &&
                    statistic.summary.content;

                  if (!flag) {
                    console.error("statistic was rejected: ", statistic);
                  }

                  return flag;
                })
                .map(statistic => (
                  <Col key={statistic.id} md={1 / 3}>
                    <Spacer margin="0 0 2rem">
                      <Stat>
                        <StyledIcon
                          className="quick-link-icon"
                          icon={statistic.icon}
                        />
                        <TextBox>
                          <Header variant="heading-4" gutterBottom={true}>
                            {statistic.header}
                          </Header>
                          <Typography
                            component="div"
                            dangerouslySetInnerHTML={{
                              __html: statistic.summary.content
                            }}
                            variant="body"
                          />
                        </TextBox>
                      </Stat>
                    </Spacer>
                  </Col>
                ))}
          </Row>
        </Container>
      </Spacer>
    </Component>
  );
};
