import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  Col,
  Container,
  Row,
  Spacer,
  Typography,
} from "../../../../components";

interface GoFundMeCampaignsProps {
  heading?: string;
  campaigns: {
    campaignId: string;
  }[];
}

const Center = styled(Row)`
  display: block;
  margin: auto;
  text-align: center;
`;

const Campaign = ({ campaignId }: { campaignId: string }) => {
  const el = useRef(null);

  useEffect(() => {
    if (campaignId && el.current) {
      const n = document.createElement("iframe");
      n.setAttribute("class", "gfm-embed-iframe");
      n.setAttribute("width", "100%");
      n.setAttribute("height", "540");
      n.setAttribute("frameborder", "0");
      n.setAttribute("scrolling", "no");
      n.setAttribute(
        "src",
        `https://www.gofundme.com/f/${campaignId}/widget/large/`
      );

      el.current.appendChild(n);
    }
  }, [campaignId, el]);

  return <Col ref={el} sm={1 / 2} lg={1 / 3} xl={1 / 4} />;
};

export const PageBuilderGoFundMeCampaigns = ({
  heading,
  campaigns,
}: GoFundMeCampaignsProps) => {
  if (!campaigns || campaigns.length === 0) {
    return null;
  }

  return (
    <Spacer margin="2rem .5rem">
      <Container className="gofundme-campaigns">
        {heading && (
          <Spacer margin="0 0 1.5rem">
            <Center>
              <Typography variant="heading-6">{heading}</Typography>
            </Center>
          </Spacer>
        )}
        {campaigns && campaigns.length > 0 && (
          <Row className="campaigns" xsJustifyContentCenter={true}>
            {campaigns.map((campaign, idx) => (
              <Campaign
                key={`${campaign.campaignId}-${idx}`}
                campaignId={campaign.campaignId}
              />
            ))}
          </Row>
        )}
      </Container>
    </Spacer>
  );
};
