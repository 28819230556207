import * as React from 'react'
import styled, { css } from 'styled-components'
import { media } from '../../styled'
type Props = {
  className?: string;
  open: boolean;
  disableDesktop?: boolean;
  speed?: number;
}

type State = {
  animate: boolean;
  open: boolean;
}

const AnimatedBox = styled.div`
  ${props => {
    if (props.disableDesktop) {
      if (!props.animate && props.open) {
        return css`
          max-height: unset;
          visibility: visible;

          ${media.breakpoint.up(
            'lg',
            css`
              max-height: unset !important;
              visibility: visible !important;
            `
          )}
        `
      } else {
        return css`
          max-height: 0px;
          visibility: hidden;

          ${media.breakpoint.up(
            'lg',
            css`
              max-height: unset !important;
              visibility: visible !important;
            `
          )}
        `
      }
    } else if (!props.animate && props.open) {
      return css`
        max-height: unset;
        visibility: visible;
      `
    } else {
      return css`
        max-height: 0px;
        visibility: hidden;
      `
    }
  }}
`

export default class Collapse extends React.Component<Props, State> {
  static defaultProps = {
    speed: 150
  }

  private ref: React.RefObject<{}>
  private animationTimeout

  constructor (props, context) {
    super(props, context)

    this.ref = React.createRef()
    this.state = { open: props.open, animate: false }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    if (nextProps.open !== prevState.open) {
      return { animate: true, open: nextProps.open }
    } else {
      return null
    }
  }

  getSnapshotBeforeUpdate = () => this.ref.current && (this.ref.current as any).getBoundingClientRect()

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { animate, open } = this.state
    const { speed } = this.props
    const container = this.ref.current as any

    let shouldAnimate = animate && prevState.open !== open

    if (open && snapshot['height'] && container.scrollHeight !== snapshot['height']) {
      shouldAnimate = true
    }

    if (shouldAnimate) {
      if (this.animationTimeout) {
        clearTimeout(this.animationTimeout)
      }

      container.style[`max-height`] = `${snapshot['height']}px`
      container.style.overflow = 'hidden'
      container.style.visibility = 'visible'

      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          container.style.transition = `max-height ${speed}ms ease-in-out`
          container.style[`max-height`] = open ? `${container.scrollHeight}px` : '0px'

          this.animationTimeout = setTimeout(() => {
            container.removeAttribute('style')
            this.setState({ animate: false })
          }, speed)
        })
      })
    }
  }

  componentWillUnmount () {
    if (this.animationTimeout) {
      clearTimeout(this.animationTimeout)
    }
  }

  render () {
    const { children, className = null, disableDesktop } = this.props
    const { open } = this.state

    return (
      <AnimatedBox
        className={className}
        children={children}
        disableDesktop={disableDesktop}
        open={open}
        ref={this.ref}
      />
    )
  }
}
