import * as React from "react";
import Form from "./FormSurveyGizmoStyles";
import { Spacer, Typography } from "../../../../components";

type SurveyGizmoProps = {
  header: string;
  surveyId: string;
  surveyName: string;
};

export default class extends React.Component<SurveyGizmoProps, {}> {
  private divId: string;

  constructor(props) {
    super(props);
    this.divId = `sg-form-${this.props.surveyId}`;
  }

  componentDidMount() {
    if (typeof window === "undefined" || !this.divId) {
      return;
    }

    import("postscribe").then(({ default: postscribe }) => {
      const src = `https://survey.alchemer.com/s3/${this.props.surveyId}/${
        this.props.surveyName
      }?__no_style=true&__output=embedjs&__ref=${encodeURIComponent(
        document.location.href
      )}`;
      postscribe(
        `#${this.divId}`,
        `<script type="text/javascript" src="${src}"><\/script>`
      );
    });
  }

  render() {
    if (this.props === null || typeof this.props !== "object") {
      return null;
    }

    if (this.props.surveyId && this.props.surveyName) {
      return (
        <Form>
          {this.props.header && (
            <Spacer padding="2rem 1rem">
              <Typography variant="heading-2">{this.props.header}</Typography>
            </Spacer>
          )}
          <div id={this.divId} />
        </Form>
      );
    }

    return null;
  }
}
