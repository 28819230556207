import React from "react";
import styled from "styled-components";

import { Icon } from "../..";
import { HurleyThemeProps } from "../../../styled";
import { Quicksand } from "../../Typography/TypographyFonts";

export const StyledTextInput = styled.div`
  display: block;
  margin-bottom: 1rem;
  label {
    color: ${({ theme }: HurleyThemeProps) => theme.colors.named.doveGray};
    display: block;
    font-size: 1rem;
    font-family: ${Quicksand.family};
    font-weight: ${Quicksand.weight.medium};
    margin-bottom: 0.25rem;
  }
  .control {
    display: flex;
    .input {
      background-color: ${({ theme }: HurleyThemeProps) =>
        theme.colors.named.white};
      background-clip: padding-box;
      border: 1px solid
        ${({ theme }: HurleyThemeProps) => theme.colors.named.silver};
      border-radius: 0;
      color: ${({ theme }: HurleyThemeProps) => theme.colors.named.doveGray};
      display: block;
      font-size: 1rem;
      font-weight: 300;
      line-height: 1.5;
      outline: none;
      padding: 0.5rem 1rem;
      flex: 1;
      &.is-danger {
        border-color: ${({ theme }: HurleyThemeProps) =>
          theme.colors.named.mojo};
      }
      &:focus {
        border-color: ${({ theme }: HurleyThemeProps) => theme.colors.primary};
      }
      &::placeholder {
        color: ${({ theme }: HurleyThemeProps) => theme.colors.named.silver};
        opacity: 1;
      }
      &:disabled,
      &[readonly] {
        background-color: ${({ theme }: HurleyThemeProps) =>
          theme.colors.named.whisper};
        opacity: 1;
      }
    }
    .icon {
      background: ${({ theme }: HurleyThemeProps) => theme.colors.named.white};
      border: 1px solid
        ${({ theme }: HurleyThemeProps) => theme.colors.named.silver};
      border-radius: 0;
      color: ${({ theme }: HurleyThemeProps) => theme.colors.named.doveGray};
      font-size: 1rem;
      line-height: 1.5;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 0.5rem;
    }
    &.has-icons-left {
      .icon.is-left {
        border-right-width: 0;
        padding: 0.5rem;
      }
    }
    &.has-icons-right {
      .icon.is-right {
        border-left-width: 0;
        padding: 0.5rem;
      }
    }
    &.is-danger {
      color: ${({ theme }: HurleyThemeProps) => theme.colors.named.mojo};
      .input,
      .icon {
        border-color: ${({ theme }: HurleyThemeProps) =>
          theme.colors.named.mojo};
      }
      .icon {
        background: ${({ theme }: HurleyThemeProps) => theme.colors.named.mojo};
        color: ${({ theme }: HurleyThemeProps) =>
          theme.colors.named.mojoContrast};
      }
    }
  }
  .help {
    display: block;
    font-size: 1rem;
    margin-top: 0.25rem;
  }
  .is-danger {
    color: ${({ theme }: HurleyThemeProps) => theme.colors.named.mojo};
  }
`;

export interface TextInputProps {
  label?: string;
  name: string;
  placeholder: string;
  register: any;
  required?: boolean;
  error?: string;
  help?: string;
}

export const TextInput = ({
  label,
  name,
  placeholder,
  register,
  required,
  error,
  help,
}: TextInputProps) => (
  <StyledTextInput>
    {label && <label>{label}</label>}
    <div className={`control has-icons-right ${error ? "is-danger" : ""}`}>
      <input
        className={`input`}
        name={name}
        placeholder={placeholder}
        ref={register({ required })}
      />
      {error && (
        <span className="icon is-right">
          <Icon className="icon-primary" icon="warning" />
        </span>
      )}
    </div>
    {help && <div className="help">{help}</div>}
    {error && <div className="help is-danger">{error}</div>}
  </StyledTextInput>
);
